import produce from 'immer';
import { DistributionCategoriesType, WhereToBuyType, CitiesType, WhereToBuyDataType } from 'services/wheretobuy';
import {
  DISTRIBUTION_CATEGORIES_SUCCESS,
  DISTRIBUTION_LIST_SUCCESS,
  GET_PROVINCE_LIST_SUCCESS,
  WhereToBuyStateType,
  ActionType,
  DISTRIBUTION_LIST_REQUEST,
  DISTRIBUTION_LIST_ERROR,
} from 'store/types/where_to_buy';

const initialState: WhereToBuyStateType = {
  cities: {
    success: false,
    message: '',
    loaded: false,
    data: [],
  },
  distributionList: {
    loaded: false,
    loading: false,
  },
  distributionCategoryList: {
    data: [],
    message: '',
    success: false,
    loaded: false,
  },
  distributionViewList: [],
};

export const whereToBuyReducer = produce((draft: WhereToBuyStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case DISTRIBUTION_CATEGORIES_SUCCESS:
      draft.distributionCategoryList = action.payload.data as DistributionCategoriesType;
      draft.distributionCategoryList.loaded = true;
      return draft;
    case DISTRIBUTION_LIST_REQUEST:
      draft.distributionList.loading = true;
      return draft;
    case DISTRIBUTION_LIST_SUCCESS:
      draft.distributionList[action.payload.categoryId as number] = action.payload.data as WhereToBuyType;
      if (!action.payload.page || action.payload.page === 1) {
        draft.distributionViewList = action.payload.data.data as WhereToBuyDataType[];
      } else {
        draft.distributionViewList = [
          ...draft.distributionViewList,
          ...(action.payload.data.data as WhereToBuyDataType[]),
        ];
      }
      draft.distributionList.loaded = true;
      draft.distributionList.loading = false;
      return draft;
    case DISTRIBUTION_LIST_ERROR:
      draft.distributionList.loading = false;
      return draft;
    case GET_PROVINCE_LIST_SUCCESS:
      draft.cities = action.payload.data as CitiesType;
      draft.cities.loaded = true;
      return draft;
    default:
      return draft;
  }
});
