import { DaikinVietnamMenuResponse } from 'services/about_us/daikin_vietnam';

export const GET_MENU_DAIKIN_VIETNAM = 'GET_MENU_DAIKIN_VIETNAM';
export const GET_MENU_DAIKIN_VIETNAM_SUCCESS = 'GET_MENU_DAIKIN_VIETNAM_SUCCESS';

export interface DaikinVietnamMenuState extends DaikinVietnamMenuResponse {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_MENU_DAIKIN_VIETNAM_SUCCESS;
  payload: DaikinVietnamMenuState;
};
