import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getPage } from 'services/common';
import { getHome } from 'services/home_page';
import { PAGE, PAGE_NOT_FOUND, PAGE_SUCCESS } from 'store/types/common';
import { HOME_PAGE } from 'store/types/home_page';

function* page(action: { type: typeof PAGE; payload: { slug: string } }) {
  try {
    const pageRes = yield call(getPage, action.payload.slug);
    yield put({ type: PAGE_SUCCESS, payload: { page: { [action.payload.slug]: pageRes } } });
  } catch {
    // TODO: Error handlers
    // const pageRes = yield call(getPage, '404');
    // yield put({ type: PAGE_SUCCESS, payload: { page: { '404': pageRes } } });
    yield put({ type: PAGE_NOT_FOUND });
  }
}

function* homePage() {
  try {
    const pageRes = yield call(getHome);
    yield put({ type: PAGE_SUCCESS, payload: { page: { home: pageRes } } });
  } catch {
    // TODO: Error handlers
  }
}

function* watchPage() {
  yield takeEvery(PAGE, page);
}

function* watchHomePage() {
  yield takeEvery(HOME_PAGE, homePage);
}

export default function* comparisonSaga() {
  yield all([watchPage(), watchHomePage()]);
}
