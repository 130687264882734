const debounce = (fn: () => void, delay: number) => {
  let timeoutInstance: ReturnType<typeof setTimeout>;

  return () => {
    clearTimeout(timeoutInstance);
    timeoutInstance = setTimeout(() => fn(), delay);
  };
};

export default debounce;
