import dayjs from 'dayjs';
import localeVi from 'dayjs/locale/vi';
import i18n from 'i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { action, useAppSelector } from 'store';
import { MENU } from 'store/types/menu';
import { SYSTEM_CONFIG } from 'store/types/system';
// import { HEADER } from 'store/types/header';
// import { FOOTER } from 'store/types/footer';
import { Button } from 'components/atoms/button';
import { Buttoncontainer } from 'components/molecules/buttoncontainer';
import { Heading } from 'components/molecules/heading';
import { FloatingContact } from 'components/organisms/FloatingContact';
import { Modal } from 'components/organisms/modal';
import { Layout } from 'components/templates/layout';
import APIPATHES from 'constants/api-pathes';
import PATHS from 'constants/pathes';
import { FacebookPixel } from 'lib/facebook-pixel';
import { GoogleAnalytics } from 'lib/google-analytics';
import { GoogleTagManager } from 'lib/google-tag-manager';
import { getLink } from 'lib/pathes';
import useDidMount from 'lib/usedidmount';
import { getCookieValue, setCookie } from 'lib/utils';
import Routes from 'pages/routes';
import { useHistory } from 'react-router-dom';
import { IntroModalContent } from 'services/system';
import { PAGE_CLEAR_NOT_FOUND } from 'store/types/common';
import Redirect301 from './redirect301';

const INTRO_MODAL_COOKIE = { name: 'introModal', value: 'disabled' };

if (i18n.language === 'vi') {
  dayjs.locale(localeVi);
}

const App = () => {
  const history = useHistory();
  const location = history.location;
  const [isOpenIntroModal, setOpenIntroModal] = useState(false);
  const disabledIntroModalRef = useRef<boolean | undefined>(undefined);

  const { t } = useTranslation();

  const { common, system, footer, header } = useAppSelector(state => ({
    common: state.common,
    header: state.header,
    footer: state.footer,
    system: state.system,
  }));

  const introModalContent = system.loaded
    ? (system.system?.data?.popupWebsite?.content as IntroModalContent | undefined)?.[i18n.language]
    : undefined;

  const onReLoad = useCallback(() => window.location.reload(), []);
  const handleUserOnPage = useCallback(() => {
    setOpenIntroModal(true);
    if (disabledIntroModalRef.current) disabledIntroModalRef.current = undefined;
  }, []);

  const handleChangeModalCheckBox = (checked: boolean) => {
    disabledIntroModalRef.current = checked;
  };

  const handleCloseModal = () => {
    setOpenIntroModal(false);
    document.body.removeEventListener('touchend', handleUserOnPage);
    document.body.removeEventListener('mouseenter', handleUserOnPage);
    if (!disabledIntroModalRef.current) return;

    const remainDayFraction = (new Date().setHours(23, 59, 59, 0) - new Date().getTime()) / (24 * 60 * 60 * 1000);
    setCookie({ ...INTRO_MODAL_COOKIE, exdays: remainDayFraction });
  };

  useEffect(() => {
    action({ type: PAGE_CLEAR_NOT_FOUND });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!introModalContent?.active) return;

    const disabledIntroModal = getCookieValue(INTRO_MODAL_COOKIE.name) === INTRO_MODAL_COOKIE.value;

    if (!disabledIntroModal) {
      document.body.addEventListener('touchend', handleUserOnPage);
      document.body.addEventListener('mouseenter', handleUserOnPage);

      return () => {
        document.body.removeEventListener('touchend', handleUserOnPage);
        document.body.removeEventListener('mouseenter', handleUserOnPage);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introModalContent?.active, location.pathname]);

  useDidMount(() => {
    // if (match?.path === '/' && !common.pages['home']) {
    //   action({ type: HOME_PAGE });
    // }

    if (typeof (window as Window).requestIdleCallback !== 'undefined') {
      (window as Window).requestIdleCallback(() => {
        action({ type: MENU });
      });

      // (window as Window).requestIdleCallback(() => {
      //   action({ type: HEADER });
      // });

      // (window as Window).requestIdleCallback(() => {
      //   action({ type: FOOTER });
      // });

      (window as Window).requestIdleCallback(() => {
        action({ type: SYSTEM_CONFIG });
      });
    } else {
      action({ type: MENU });
      // action({ type: HEADER });
      // action({ type: FOOTER });
      action({ type: SYSTEM_CONFIG });
    }
  });

  useEffect(() => {
    if (!system.loaded || !footer.loaded || !header.loaded) {
      window.scrollTo(0, 0);
    }
  }, [system.loaded, footer.loaded, header.loaded]);

  useEffect(() => {
    const pathValues = Object.values(PATHS);
    const translationItem = pathValues.find(
      item => item.en === history.location.pathname || item.vi === history.location.pathname
    );
    if (translationItem) {
      history.location.state = {
        ...history.location.state,
        urltranslations: {
          en: translationItem['en'],
          vi: translationItem['vi'],
        },
      };
    }
  }, [history.location]);

  return (
    <>
      <Layout>
        {system.system?.data?.gaTrackingIds && (
          <GoogleAnalytics scriptGoogleAnalytics={system.system.data.gaTrackingIds} />
        )}
        {system.system?.data?.gtmIds && <GoogleTagManager scriptGoogleTagManager={system.system.data.gtmIds} />}
        {system.system?.data?.pixel?.content &&
          Array.isArray(system.system?.data?.pixel?.content) &&
          system.system?.data.pixel.content.map((item) => <FacebookPixel fbPixelId={item} />)}
        <Routes />
        <Modal
          isOpen={common.isErrorSystem}
          closeModal={onReLoad}
          style={{ content: { maxWidth: 600, minHeight: 130 } }}
          isClosable={false}
          isCentered
        >
          <Heading type="h3" modifiers={['cerulean', 'nonmargintop', 'fwmedium', 'center']}>
            {t('common.heading-error')}
          </Heading>
          <Buttoncontainer
            modifiers="nonmargin"
            primary={
              <Button buttonType="round" onClick={onReLoad}>
                {t('common.btn-close')}
              </Button>
            }
          />
        </Modal>
        <FloatingContact />
        <Modal
          fullbox
          isCentered
          isOpen={isOpenIntroModal}
          closeModal={handleCloseModal}
          backgroundImageSrc={`${APIPATHES.STORAGE}${introModalContent?.image}`}
          closeIcon="close-grey"
          hasCheckBox
          checkBoxTitle={t('common.do-not-show')}
          onChangeCheckBox={e => handleChangeModalCheckBox(e.target.checked)}
          backgroundHref={getLink(introModalContent?.link?.url || '')}
          backgroundTarget={introModalContent?.link?.target}
        />
      </Layout>
      <Redirect301 />
    </>
  );
};

export default App;
