import produce from 'immer';
import { CaseStudyCategoriesResponse, CaseStudyResponse } from 'services/discovery/casestudy';
import {
  CASESTUDY_CATEGORIES_SUCCESS,
  CASESTUDIES_SUCCESS,
  CaseStudyStateType,
  ActionType,
} from 'store/types/discovery/casestudy/index';

const initialState: CaseStudyStateType = {
  categories: {
    data: [],
    message: '',
    success: false,
    loaded: false,
  },
  casestudies: {
    loaded: false,
  },
};

export const casestudyReducer = produce((draft: CaseStudyStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case CASESTUDY_CATEGORIES_SUCCESS:
      draft.categories = action.payload.data as CaseStudyCategoriesResponse;
      draft.categories.loaded = true;
      return draft;
    case CASESTUDIES_SUCCESS:
      draft.casestudies[action.payload.category as number] = draft.casestudies[action.payload.category as number]
        ? {
            ...(action.payload.data as CaseStudyResponse),
            data: [
              ...draft.casestudies[action.payload.category as number].data,
              ...(action.payload.data as CaseStudyResponse).data,
            ],
          }
        : (action.payload.data as CaseStudyResponse);
      draft.casestudies.loaded = true;
      return draft;
    default:
      return draft;
  }
});
