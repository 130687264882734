import { SolutionsResponse } from 'services/solution';
import { GuidesResponse } from 'services/discovery/guides';
import { SmartACListResponse } from 'services/services/smart_ac_selector';

export const SMART_AC = 'SMART_AC';
export const SMART_AC_SUCCESS = 'SMART_AC_SUCCESS';
export const SEND_SMART_AC = 'SEND_SMART_AC';
export const SEND_SMART_AC_SUCCESS = 'SEND_SMART_AC_SUCCESS';
export const RESET = 'RESET';

export interface SmartAcStateType {
  solutionRelative: SolutionsResponse;
  guides: GuidesResponse;
  smartAClist: SmartACListResponse;
  loaded: boolean;
}

export type ActionType = {
  type: typeof SMART_AC_SUCCESS | typeof SEND_SMART_AC_SUCCESS | typeof RESET;
  payload: { solutionRelative: SolutionsResponse; guides: GuidesResponse; smartAcList: SmartACListResponse };
};
