import { ProductTypeResponse } from 'services/product/index';
import { SolutionsResponse } from 'services/solution';

export const PRODUCT_TYPE = 'PRODUCT_TYPE';
export const PRODUCT_TYPE_SUCCESS = 'PRODUCT_TYPE_SUCCESS';
export const PRODUCT = 'PRODUCT';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_PAGE = 'PRODUCT_PAGE';
export const PRODUCT_PAGE_SUCCESS = 'PRODUCT_PAGE_SUCCESS';

export interface ProductStateType {
  productType: ProductTypeResponse;
  solutionRelative?: SolutionsResponse;
  loaded: boolean;
}

export type ActionType = {
  type: typeof PRODUCT_SUCCESS | typeof PRODUCT_TYPE_SUCCESS;
  payload: {
    productType: ProductTypeResponse;
    solutionRelative?: SolutionsResponse;
  };
};
