import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  CASESTUDY_CATEGORIES,
  CASESTUDY_CATEGORIES_SUCCESS,
  CASESTUDIES,
  CASESTUDIES_SUCCESS,
} from 'store/types/discovery/casestudy/index';

import { getCaseStudyCategoryList, getCaseStudyListByCategorySlug } from 'services/discovery/casestudy';

function* getCategories() {
  const data = yield call(getCaseStudyCategoryList);
  yield put({ type: CASESTUDY_CATEGORIES_SUCCESS, payload: { data } });
}

function* watchCategories() {
  yield takeEvery(CASESTUDY_CATEGORIES, getCategories);
}

function* getCasestudies(action: { type: string; payload: { category_ids: number; page: number } }) {
  const data = yield call(getCaseStudyListByCategorySlug, action.payload.category_ids, action.payload.page);
  yield put({ type: CASESTUDIES_SUCCESS, payload: { data, category: action.payload.category_ids } });
}

function* watchCasestudies() {
  yield takeEvery(CASESTUDIES, getCasestudies);
}

export default function* casestudySaga() {
  yield all([watchCategories(), watchCasestudies()]);
}
