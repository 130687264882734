import { Button } from 'components/atoms/button';
import { Image } from 'components/atoms/image';
import { Heading } from 'components/molecules/heading';
import { Col, Row } from 'components/organisms/grid';
import { Section } from 'components/organisms/section';
import { Notfound } from 'components/pages/notfound';
import { getCurrentLanguage } from 'i18n';
import { BasePageProps } from 'lib/component';
import { Seo } from 'lib/seo';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import imgNotfound from 'assets/images/notfound.png';
import { useAppSelector } from 'store';
import { removePrefixCanonical } from 'lib/utils';

const IndexPage: React.FC<BasePageProps> = () => {
  const language = getCurrentLanguage();
  const history = useHistory();

  const handleClearNotFound = () => {
    const currentLang = language && language !== 'vi' ? `/${language}` : '/';
    history.push(currentLang);
  };

  return (
    <Notfound
      helmet={
        <Seo
          metaData={{
            description:
              language === 'vi'
                ? 'Trang bạn đang tìm kiếm dường như đã được di chuyển, xóa hoặc không tồn tại.'
                : `The page you are looking for seems to have been moved, deleted or doesn't exist.`,
            keywords: '',
            extraData: {
              canonical: removePrefixCanonical(window.location.origin + window.location.pathname),
              ogDescription: '',
              ogImage: '',
              ogTitle: '',
              ogType: '',
            },
            title: language === 'vi' ? 'Không tìm thấy trang' : 'Page not found',
          }}
        />
      }
    >
      <React.Fragment>
        <Helmet>
          <meta name="render:status_code" content="404" />
        </Helmet>
        <Section>
          <Row>
            <Col className="my-auto" xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
              <Heading type="h1" modifiers="fwmedium">
                {language === 'vi' ? 'Không tìm thấy trang' : 'Page not found'}
              </Heading>
              <p
                className="u-original-browser-style-lists"
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'vi'
                      ? 'Trang bạn đang tìm kiếm dường như đã được di chuyển, xóa hoặc không tồn tại.'
                      : `The page you are looking for seems to have been moved, deleted or doesn't exist.`,
                }}
              />
              <div style={{ maxWidth: 182 }}>
                <Button buttonType="round" onClick={handleClearNotFound}>
                  {language === 'vi' ? 'Về Trang Chủ' : 'Return Home page'}
                </Button>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
              <Image alt={language === 'vi' ? 'Không tìm thấy trang' : 'Page not found'} src={imgNotfound} />
            </Col>
          </Row>
        </Section>
      </React.Fragment>
    </Notfound>
  );
};

export default IndexPage;
