import produce from 'immer';
import { HEADER_SUCCESS, HEADER_LOADING, HEADER_END_LOADING, HeaderStateType, ActionType } from 'store/types/header';

const initialState: HeaderStateType = {
  main: { data: [], message: '', success: false },
  second: { data: [], message: '', success: false },
  loaded: false,
  loading: true,
};

export const headerReducer = produce((draft: HeaderStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case HEADER_LOADING:
      draft.loading = true;
      return draft;
    case HEADER_END_LOADING:
      draft.loading = false;
      return draft;
    case HEADER_SUCCESS:
      draft.main = { ...action.payload.main, data: action.payload.main.data };
      draft.second = { ...action.payload.second, data: action.payload.second.data };
      draft.loaded = true;
      draft.loading = false;
      return draft;
    default:
      return draft;
  }
});
