import produce from 'immer';
import { FAQListByCategorySlugResponse } from 'services/services/faq';
import {
  GET_FAQ_SUCCESS,
  GET_FAQ_PAGE_SUCCESS,
  FaqPageCombineType,
  FaqPageStateType,
  ActionType,
} from 'store/types/services/faq';

const initialState: FaqPageStateType = {
  categories: {
    data: [],
    message: '',
    success: false,
  },
  contents: {},
  relativeGuides: {
    data: [],
    message: '',
    success: false,
    loaded: false,
    meta: {
      limit: 0,
      page: 0,
      total: 0,
      totalPages: 0,
    },
  },
  loaded: false,
};

export const faqReducer = produce((draft: FaqPageStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_FAQ_PAGE_SUCCESS:
      draft = {
        ...draft,
        ...(action.payload.data as FaqPageCombineType),
        loaded: true,
      };
      return draft;
    case GET_FAQ_SUCCESS:
      draft.contents[action.payload.faqCategory as string] = action.payload.data as FAQListByCategorySlugResponse;
      return draft;
    default:
      return draft;
  }
});
