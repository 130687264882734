import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getPageRedirect } from 'services/redirect';
import { REDIRECT, REDIRECT_ERROR, REDIRECT_SUCCESS } from 'store/types/redirect';

function* redirect(action: { type: typeof REDIRECT; payload: string }) {
  try {
    const getRedirectData = yield call(getPageRedirect, action.payload);
    yield put({ type: REDIRECT_SUCCESS, payload: getRedirectData });
  } catch {
    yield put({ type: REDIRECT_ERROR });
  }
}

function* watchRedirect() {
  yield takeEvery(REDIRECT, redirect);
}

export default function* redirectSaga() {
  yield all([watchRedirect()]);
}
