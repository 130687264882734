import React, { useMemo } from 'react';
import { mapModifiers } from 'lib/component';
import { Text } from 'components/atoms/text';

type Modifier =
  | 'white'
  | 'center'
  | 'smallmarginbottom'
  | 'mediummarginbottom'
  | 'nonmargintop'
  | 'nonmarginbottom'
  | 'fwmedium'
  | 'cerulean'
  | 'dimgray'
  | 'line'
  | 'halfline'
  | 'fwbold'
  | 'greyishbrown'
  | 'boxshadow'
  | 'textshadow'
  | 'subordered';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  modifiers?: Modifier | Modifier[];
  stepNumber?: string;
  tags?: React.ReactNode;
  type?: HeadingType;
  category?: string;
  useParagraph?: boolean;
}

export const Heading: React.FC<Props> = ({
  modifiers,
  stepNumber,
  tags,
  type,
  category,
  useParagraph,
  children,
  ...props
}) => {
  const renderHeadingText = useMemo(() => {
    switch (type) {
      case 'h1':
        return (
          <h1 {...props} className="m-heading_text">
            {children}
          </h1>
        );
      case 'h2':
        return (
          <h2 {...props} className="m-heading_text">
            {children}
          </h2>
        );
      case 'h3':
        return (
          <h3 {...props} className="m-heading_text">
            {children}
          </h3>
        );
      case 'h4':
        return (
          <h4 {...props} className="m-heading_text">
            {children}
          </h4>
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <div className={mapModifiers('m-heading', `type${type}`, modifiers, stepNumber && 'withstep')}>
      {tags}
      {category && (
        <div className="m-heading_category">
          <Text modifiers="cerulean">{category}</Text>
        </div>
      )}
      <div className="m-heading_body">
        {stepNumber && <span className="m-heading_stepnumber">{stepNumber}</span>}
        {useParagraph ? <div className="m-heading_text">{children}</div> : renderHeadingText}
      </div>
    </div>
  );
};

Heading.defaultProps = {
  type: 'h2',
};
