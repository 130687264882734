import produce from 'immer';
import { ERROR_CODES_SUCCESS, ErrorCodesStateType, ActionType } from 'store/types/services/error_codes/index';

const initialState: ErrorCodesStateType = {
  relativeGuides: {
    data: [],
    message: '',
    success: false,
    loaded: false,
    meta: {
      limit: 0,
      page: 0,
      total: 0,
      totalPages: 0,
    },
  },
  errorCodes: {
    data: [],
    message: '',
    success: false,
  },
  loaded: false,
};

export const errorCodesReducer = produce((draft: ErrorCodesStateType = initialState, action: ActionType) => {
  if (action.type === ERROR_CODES_SUCCESS) {
    draft = {
      ...action.payload,
      loaded: true,
    };
  }
  return draft;
});
