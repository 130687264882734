import axios, { CancelTokenSource } from 'axios';
import { AutoComplete } from 'components/atoms/autocomplete';
import { Icon } from 'components/atoms/icon';
import { Image } from 'components/atoms/image';
import { Language, LanguageItem } from 'components/atoms/language';
import { Menu, MenuItem, SubMenu, SubMenuCardItem, SubMenuItem } from 'components/molecules/menu';
import { Container } from 'components/organisms/grid';
import { Link } from 'components/utils/link';
import { default as APIPATH, default as APIPATHES } from 'constants/api-pathes';
import { getCurrentLanguage } from 'i18n';
import { mapModifiers } from 'lib/component';
import debounce from 'lib/debounce';
import { changeLanguage } from 'lib/history';
import { getMenuLink } from 'lib/menu';
import { getPath } from 'lib/pathes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItemType } from 'services/header';
import { SearchSuggestionType, getSearchSuggestion } from 'services/search';
import { action, useAppSelector } from 'store';
import { FOOTER_LOADING } from 'store/types/footer';

type ParamsOfRenderingMenu = {
  menus: Array<MenuItemType>;
  hasClassCustom?: boolean;
  isBottomMenu?: boolean;
  isLoading?: boolean;
};

export const HeaderContext = React.createContext<{ closeMenuBar: () => void } | null>(null);

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const {
    header: { main, second, loading },
    system: { system },
  } = useAppSelector(state => ({ header: state.header, system: state.system }));
  const [isOpenMenuBar, setOpenMenuBar] = useState<boolean>(false);
  const [isOpenSearchBar, setOpenSearchBar] = useState<boolean>(false);
  const [searchSuggestion, setSearchSuggestion] = useState<SearchSuggestionType>();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const mobileSearchRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const location = useLocation();
  const handleCloseMenuBar = useCallback(() => isOpenMenuBar && window.innerWidth <= 991 && setOpenMenuBar(false), [
    isOpenMenuBar,
  ]);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const handleSearch = useCallback(() => {
    cancelTokenSource.current && cancelTokenSource.current.cancel();

    const inputTarget = inputRef.current;
    const isSearchPage = new RegExp(`^${getPath('SEARCH')}$`).test(history.location.pathname);
    (!isSearchPage || (isSearchPage && inputTarget?.value)) && setOpenSearchBar(false);

    if (inputTarget) {
      if (isSearchPage && inputTarget.value === new URLSearchParams(history.location.search).get('keyword')) return;
      history.push({ pathname: getPath('SEARCH'), search: `keyword=${inputTarget.value}` });
      inputTarget.value = '';
    }

    handleCloseMenuBar();
    setSearchSuggestion(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseMenuBar]);

  useEffect(() => {
    if (!headerRef.current) return;

    const handleClickOutSide = (e: Event) => !headerRef.current?.contains(e.target as Node) && handleCloseMenuBar();
    const handleResizeWindow = () => window.innerWidth > 991 && isOpenMenuBar && setOpenMenuBar(false);

    window.addEventListener('click', handleClickOutSide);
    window.addEventListener('resize', handleResizeWindow);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [handleCloseMenuBar, isOpenMenuBar]);

  useEffect(() => {
    const htmlElm = document.querySelector('html');
    if (htmlElm) {
      htmlElm.style.overflowY = isOpenSearchBar ? 'hidden' : 'unset';
    }
  }, [isOpenSearchBar]);

  useEffect(() => {
    const mobileSearchTarget = mobileSearchRef.current;
    const searchTarget = searchRef.current;

    if (!searchTarget || !mobileSearchTarget) return undefined;

    const moveAutoComplete = (mqle: MediaQueryList | MediaQueryListEvent) => {
      const oldParent = mqle.matches ? searchTarget : mobileSearchTarget;
      const newParent = !mqle.matches ? searchTarget : mobileSearchTarget;

      while (oldParent.childNodes.length > 0) {
        newParent.appendChild(oldParent.childNodes[0]);
      }

      if (!mqle.matches) {
        setOpenSearchBar(false);
      }
    };

    const mql = window.matchMedia('(max-width: 991px)');
    if (typeof mql.addEventListener === 'function') {
      mql.addEventListener('change', moveAutoComplete);
    } else {
      mql.addListener(moveAutoComplete);
    }
    moveAutoComplete(mql);

    return () => {
      if (typeof mql.removeEventListener === 'function') {
        mql.removeEventListener('change', moveAutoComplete);
      } else {
        mql.removeListener(moveAutoComplete);
      }
    };
  }, []);

  const getMenuCards = useCallback((data: MenuItemType) => {
    const menuCards: Array<MenuItemType> = [];

    const getMenuCardsRecursive = (dataRecursive: Array<MenuItemType>) => {
      for (const i in dataRecursive) {
        dataRecursive[i].isRight && menuCards.push(dataRecursive[i]);
        dataRecursive[i].children.length > 0 && getMenuCardsRecursive(dataRecursive[i].children);
      }
    };

    if (data.children?.length > 0) {
      getMenuCardsRecursive(data.children);
    }

    return menuCards;
  }, []);

  const getSlugChildren = useCallback((menu: MenuItemType) => {
    const slugChildren: string[] = [];

    const getSlugTableRecursive = (menuRecursive: MenuItemType) => {
      menuRecursive.children.forEach(item => {
        item.reference && slugChildren.push(item.reference.slug);
        item.children.length > 0 && getSlugTableRecursive(item);
      });
    };

    if (menu.children.length > 0) {
      getSlugTableRecursive(menu);
    }

    return slugChildren;
  }, []);

  const convertToPattern = useCallback((link: string) => new RegExp(`^${link}/|^${link}$`), []);

  const getSubMenu = useCallback(
    (data: MenuItemType) => {
      const getSubMenuRecursive = (dataRecursive: Array<MenuItemType>): React.ReactNode => (
        <SubMenu>
          {dataRecursive.map(menuItem => {
            if (menuItem.isRight === 0) {
              // Current link of the menu item
              const menuLink = getMenuLink(menuItem, getCurrentLanguage(), data);
              const matchPathName = ['/danh-muc-giai-phap', '/en/category-solutions'].some(e => new RegExp(e).test(window.location.pathname));
              const isActiveMenu = ['solution'].some(e => new RegExp(e).test(menuItem.reference?.templateCode || '')) && matchPathName;

              return (
                <SubMenuItem
                  active={
                    menuLink.includes('http')
                      ? false
                      : convertToPattern(menuLink).test(location.pathname) ||
                      getSlugChildren(menuItem).some(slugChild =>
                        convertToPattern(
                          (getCurrentLanguage() === 'vi' ? '' : `/${getCurrentLanguage()}`) + `/${slugChild}`
                        ).test(location.pathname)
                      ) ||
                      isActiveMenu
                  }
                  key={menuItem.id}
                  title={menuItem.title}
                  to={menuLink}
                  target={menuItem.target}
                  cssClass={menuItem.cssClass}
                >
                  {menuItem.children.length > 0 && getSubMenuRecursive(menuItem.children)}
                </SubMenuItem>
              );
            }
            return null;
          })}
        </SubMenu>
      );

      if (data.children.length > 0) {
        return getSubMenuRecursive(data.children);
      }

      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const renderMenu = useMemo(
    () => ({ menus, hasClassCustom, isBottomMenu, isLoading }: ParamsOfRenderingMenu) => {
      return (
        <HeaderContext.Provider value={{ closeMenuBar: handleCloseMenuBar }}>
          {menus?.map(menu => {
            const menuCards = getMenuCards(menu).map(menuCard => (
              <SubMenuCardItem
                key={menuCard.id}
                title={menuCard.title}
                to={getMenuLink(menuCard, getCurrentLanguage(), menu)}
                target={menuCard.target}
                cardSrc={`${APIPATH['STORAGE']}${menuCard.image}`}
                cssClass={menuCard.cssClass}
              />
            ));

            // Current link of the menu item
            const menuLink = getMenuLink(menu, getCurrentLanguage());
            const isContactUs = menu.cssClass === 'contact-us';

            const matchPathName = ['/danh-muc-giai-phap', '/en/category-solutions'].some(e => new RegExp(e).test(window.location.pathname));
            const isActiveMenu = ['solution'].some(e => new RegExp(e).test(menu.reference?.templateCode || '')) && matchPathName;

            return (
              <Menu key={menu.id}>
                <MenuItem
                  active={
                    menuLink.includes('http')
                      ? false
                      : convertToPattern(menuLink).test(location.pathname) ||
                      getSlugChildren(menu).some(slugChild =>
                        convertToPattern(
                          (getCurrentLanguage() === 'vi' ? '' : `/${getCurrentLanguage()}`) + `/${slugChild}`
                        ).test(location.pathname)
                      ) ||
                      isActiveMenu
                  }
                  title={menu.title}
                  to={menuLink}
                  target={menu.target}
                  menuCard={menuCards.length > 0 && menuCards}
                  modifiers={
                    hasClassCustom
                      ? menu.cssClass === 'highlight'
                        ? ['bold', 'nonarrow', 'uppercase', 'button']
                        : ['bold', 'nonarrow', 'uppercase']
                      : undefined
                  }
                  cssClass={menu.cssClass}
                  isBottomMenu={isBottomMenu}
                  loading={isLoading}
                  icon={!isContactUs ? menu.icon : ''}
                  isContactUs={isContactUs}
                >
                  {getSubMenu(menu)}
                </MenuItem>
              </Menu>
            );
          })}
        </HeaderContext.Provider>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCloseMenuBar, location.pathname]
  );

  const onChangeLangue = useCallback(
    (lng: string) => {
      if (lng !== getCurrentLanguage()) {
        changeLanguage(lng);
      }
      action({ type: FOOTER_LOADING });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCurrentLanguage()]
  );

  const handleSearchSuggestion = useCallback(async () => {
    const inputValue = inputRef.current?.value.trim();
    if (!inputValue) {
      setSearchSuggestion(undefined);
      return undefined;
    }

    cancelTokenSource.current && cancelTokenSource.current.cancel();
    cancelTokenSource.current = axios.CancelToken.source();

    const searchSuggestionRes = await getSearchSuggestion({ keyword: inputValue, limit: 4 }, cancelTokenSource.current);
    setSearchSuggestion(searchSuggestionRes);
  }, []);

  const handleSearchSuggestionDebounce = debounce(handleSearchSuggestion, 100);

  const handleSelectSuggestion = useCallback(
    (option: string) => {
      const inputTarget = inputRef.current;
      if (inputTarget) {
        inputTarget.value = option;
        handleSearch();
      }
    },
    [handleSearch]
  );

  const menuRight = useMemo(() => main.data.filter((item) => item.isRight), [main.data]);

  return (
    <div className="o-header" ref={headerRef}>
      <div className="o-header_wraplogo">
        <div
          className={mapModifiers('o-header_hamburger', isOpenMenuBar && 'open')}
          onClick={() => setOpenMenuBar(!isOpenMenuBar)}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {system && (
          <Link className="o-header_logo" to={getPath('APP')} onClick={handleCloseMenuBar}>
            <Image
              lazy={false}
              src={`${APIPATHES.STORAGE}${system.data.logoWebsite.content}`}
              alt={system.data.logoWebsite.key}
            />
          </Link>
        )}
        <Icon iconName="search-black" onClick={() => setOpenSearchBar(true)} />
      </div>
      <div className={mapModifiers('o-header_wrapcontent', isOpenMenuBar && 'open')}>
        <div className="o-header_bottom">
          <Container className="o-header_menubottom">
            {renderMenu({ menus: second.data, hasClassCustom: true, isBottomMenu: true, isLoading: loading })}
            <div className="o-header_search">
              <div ref={searchRef}>
                <AutoComplete
                  placeholder={t('header.search')}
                  onClick={handleSearch}
                  ref={inputRef}
                  onKeyDown={e => e.keyCode === 13 && handleSearch()}
                  onChange={handleSearchSuggestionDebounce}
                  options={searchSuggestion?.data.map(({ data, groupName }) => ({
                    groupName,
                    optionNames: data.map(({ name, question, title }) => name || question || title || ''),
                  }))}
                  onSelectOption={option => handleSelectSuggestion(option)}
                />
              </div>
            </div>
          </Container>
        </div>
        <Container className="o-header_top">
          {system && (
            <Link className="o-header_logo" to={getPath('APP')}>
              <Image
                lazy={false}
                src={`${APIPATHES.STORAGE}${system.data.logoWebsite.content}`}
                alt={system.data.logoWebsite.key}
              />
            </Link>
          )}
          <div className="o-header_menutop">
            {renderMenu({ menus: main.data, isLoading: loading })}
            <div className="o-header_wraplang">
              <Language langActived={getCurrentLanguage() || ''}>
                <LanguageItem
                  title="VI"
                  value="vi"
                  active={getCurrentLanguage() === 'vi'}
                  onClick={() => onChangeLangue('vi')}
                />
                <LanguageItem
                  title="EN"
                  value="en"
                  active={getCurrentLanguage() === 'en'}
                  onClick={() => onChangeLangue('en')}
                />
              </Language>
            </div>
            <div className="o-header_contactUs_wrap">
              {
                menuRight.map((item) => {
                  if (item.cssClass === 'contact-us') {
                    return (
                      <Link to={getMenuLink(item, getCurrentLanguage())} key={`o-header_menutop-${item.id}`}>
                        <div className="o-header_contactUs">
                          {
                            item.icon && <div className="o-header_contactUs_icon"><Image alt="mail" src={`${APIPATHES.STORAGE}${item.icon}`} /></div>
                          }
                          <span className="o-header_contactUs-text">{item.title}</span>
                        </div>
                      </Link>
                    );
                  }
                  return null;
                })
              }
            </div>
          </div>
        </Container>
      </div>

      <div className={mapModifiers('o-header_wrapmobilesearch', isOpenSearchBar && 'open')}>
        <div className="o-header_mobilesearch">
          <Icon iconName="arrow-back-black" onClick={() => setOpenSearchBar(false)} />
          <div ref={mobileSearchRef} />
        </div>
      </div>
    </div>
  );
};
