import { GuidesResponse } from 'services/discovery/guides';
import { NewsResponse } from 'services/discovery/news';

export const HOME = 'HOME';
export const HOME_STATIC = 'HOME_STATIC';
export const HOME_NEWS = 'HOME_NEWS';
export const HOME_GUILD = 'HOME_GUILD';
export const HOME_SUCCESS = 'HOME_SUCCESS';
export const HOME_NEWS_SUCCESS = 'HOME_NEWS_SUCCESS';
export const HOME_GUILD_SUCCESS = 'HOME_GUILD_SUCCESS';
export const HOME_PAGE = 'HOME_PAGE';

export type ActionType = {
  type: typeof HOME_SUCCESS | typeof HOME_NEWS_SUCCESS | typeof HOME_GUILD_SUCCESS | typeof HOME_STATIC;
  payload: { newsResponse: NewsResponse; guidesResponse: GuidesResponse };
};
