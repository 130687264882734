import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ERROR_CODES, ERROR_CODES_SUCCESS } from 'store/types/services/error_codes';
import { getGuidesList, GuidesResponse } from 'services/discovery/guides';
import { ErrorCodesResponse, getErrorCodesList } from 'services/services/error_codes';

function* errorCodes() {
  const [relativeGuides, errorCodesRes] = (yield all([
    call(getGuidesList, { page: 1, limit: 7, show_in_error_code_page: true }),
    call(getErrorCodesList),
  ])) as Array<GuidesResponse | ErrorCodesResponse>;
  yield put({ type: ERROR_CODES_SUCCESS, payload: { relativeGuides, errorCodes: errorCodesRes } });
}

function* watchErrorCodes() {
  yield takeEvery(ERROR_CODES, errorCodes);
}

export default function* errorCodesSaga() {
  yield all([watchErrorCodes()]);
}
