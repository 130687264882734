import produce from 'immer';
import { GET_CONTACT_SUCCESS, ContactStateType, ActionType } from 'store/types/contact/index';

const initialSate: ContactStateType = {
  data: [],
  message: '',
  success: false,
  loaded: false,
};

export const contactReducer = produce((draft: ContactStateType = initialSate, action: ActionType) => {
  if (action.type === GET_CONTACT_SUCCESS) {
    draft = {
      ...action.payload,
      loaded: true,
    };
  }
  return draft;
});
