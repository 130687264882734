import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getFloatingBannerService } from 'services/common';
import { GET_FLOATING_BANNER, GET_FLOATING_BANNER_SUCCESS } from 'store/types/floatingBanner';

function* floatingBanner() {
  try {
    const data = yield call(getFloatingBannerService);
    yield put({
      type: GET_FLOATING_BANNER_SUCCESS,
      payload: data
    });
  } catch {
    // TODO: Error handlers
  }
}


function* watchFloatingBanner() {
  yield takeLatest(GET_FLOATING_BANNER, floatingBanner);
}

export default function* floatingBannerSaga() {
  yield all([watchFloatingBanner()]);
}
