import produce from 'immer';
import { ActionType, RedirectStateType, REDIRECT_CLEAN, REDIRECT_ERROR, REDIRECT_SUCCESS } from 'store/types/redirect';

const initialState: RedirectStateType = {
  isError: false,
};

export const redirectReducer = produce((draft: RedirectStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case REDIRECT_SUCCESS:
      draft.redirectData = action.payload;
      return draft;
    case REDIRECT_CLEAN:
      draft.redirectData = undefined;
      draft.isError = false;
      return draft;
    case REDIRECT_ERROR:
      draft.isError = true;
      return draft;
    default:
      return draft;
  }
});
