import { Loading } from 'components/atoms/loading';
import { getPathes } from 'lib/pathes';
import OtherNotfound from 'pages/otherNotfound';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from 'store';
const ProtectedRoute = lazy(() => import('lib/protected-route'));
const ProductComparison = lazy(() => import('lib/product-comparison'));
const CaseStudyDetailPage = lazy(() => import('pages/discovery/casestudy/detail'));
const NewsPage = lazy(() => import('pages/discovery/news'));
const GuidesPage = lazy(() => import('pages/discovery/guides'));
const NewsDetailPage = lazy(() => import('pages/discovery/news/detail'));
const GuidesDetailPage = lazy(() => import('pages/discovery/guides/detail'));
const SolutionDetailPage = lazy(() => import('pages/solution/detail'));
const SolutionPage = lazy(() => import('pages/solution'));
const ProductListPage = lazy(() => import('pages/product/list'));
const ProductDetailPage = lazy(() => import('pages/product/list/detail'));
const ComparisontPage = lazy(() => import('pages/comparison/index'));
// const AirPurifierPage = lazy(() => import('pages/airPurifier/index'));
// const SiteMapPage = lazy(() => import('pages/sitemap'));

function Routes() {
  const { isNotFound } = useAppSelector(state => state.common);
  return (
    <Suspense fallback={<Loading />}>
      <div style={{ minHeight: isNotFound ? 'auto' : 'calc(100vh - 144px)' }}>
        {/* <div style={{ display: isNotFound ? 'block' : 'none' }}>
          <Notfound />
        </div> */}
        <Switch>
          {/* <Route path={getPathes('AIRPURIFIER')} exact component={AirPurifierPage} /> */}
          <Route path={getPathes('CMP')} exact component={ComparisontPage} />
          {/* <Route path={getPath('SITEMAP')} exact component={SiteMapPage} /> */}
          <Route path={['/en/:slugPage?', '/:slugPage?']} exact component={ProtectedRoute} />
          <Route
            path={getPathes('DCVCS').map(pathValue => `${pathValue}/:slugCasestudy`)}
            exact
            component={CaseStudyDetailPage}
          />
          <Route
            path={getPathes('DCVNC').map(pathValue => `${pathValue}/:slugCategory`)}
            exact
            component={NewsPage}
          />
          <Route
            path={getPathes('DCVN').map(pathValue => `${pathValue}/:slugNew`)}
            exact
            component={NewsDetailPage}
          />
          <Route
            path={getPathes('DCVGC').map(pathValue => `${pathValue}/:slugCategory`)}
            exact
            component={GuidesPage}
          />
          <Route
            path={getPathes('DCVG').map(pathValue => `${pathValue}/:slugGuide`)}
            exact
            component={GuidesDetailPage}
          />
          <Route
            path={getPathes('SLT').map(pathValue => `${pathValue}/:slugSolution`)}
            exact
            component={SolutionDetailPage}
          />
          <Route
            path={getPathes('CategorySLT').map(pathValue => `${pathValue}/:slugCategorySolution`)}
            exact
            component={SolutionPage}
          />
          <Route
            path={getPathes('PRD').map(pathValue => `${pathValue}/:slugType`)}
            exact
            render={() => <ProductComparison page={<ProductListPage />} />}
          />
          <Route
            path={getPathes('PRD').map(pathValue => `${pathValue}/:slugType/:slugDetail`)}
            exact
            render={() => <ProductComparison page={<ProductDetailPage />} />}
          />
          <Route
            path={getPathes('PRD').map(pathValue => `${pathValue}/:slugType/:slugDetail/:slugSeries`)}
            exact
            render={() => <ProductComparison page={<ProductDetailPage />} />}
          />
          <Route path="*" component={OtherNotfound} />
        </Switch>
      </div>
    </Suspense>
  );
}

export default Routes;
