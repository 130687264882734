import { all } from 'redux-saga/effects';
import commonSaga from 'store/sagas/common';
import systemConfigSaga from 'store/sagas/system';
// import headerSaga from 'store/sagas/header';
// import footerSaga from 'store/sagas/footer';
import homeSaga from 'store/sagas/home_page';
import casestudySaga from 'store/sagas/discovery/casestudy';
import productSaga from 'store/sagas/product';
import pichonkunSaga from 'store/sagas/discovery/pichonkun';
import whereToBuySaga from 'store/sagas/where_to_buy';
import contactSaga from 'store/sagas/contact';
import faqSaga from 'store/sagas/services/faq';
import catalogueSaga from 'store/sagas/services/catalogue';
import solutionSaga from 'store/sagas/solution';
import newsSaga from 'store/sagas/discovery/news';
import guidesSaga from 'store/sagas/discovery/guides';
import smartAcSelectorSaga from 'store/sagas/services/smart_ac_selector';
import errorCodesSaga from 'store/sagas/services/error_codes';
import comparisonSaga from 'store/sagas/comparison';
import daikinVietNamSaga from 'store/sagas/about_us/daikin_vietnam';
import menuServiceSaga from 'store/sagas/services';
import technologySaga from 'store/sagas/discovery/technology';
import trainingSaga from 'store/sagas/partner/training';
import redirectSaga from 'store/sagas/redirect';
import menuSaga from './menu';
import floatingBannerSaga from './floatingBanner';

export default function* rootSaga(): Generator {
  yield all([
    commonSaga(),
    systemConfigSaga(),
    // headerSaga(),
    // footerSaga(),
    menuSaga(),
    homeSaga(),
    casestudySaga(),
    guidesSaga(),
    whereToBuySaga(),
    contactSaga(),
    productSaga(),
    solutionSaga(),
    catalogueSaga(),
    faqSaga(),
    newsSaga(),
    smartAcSelectorSaga(),
    errorCodesSaga(),
    pichonkunSaga(),
    comparisonSaga(),
    daikinVietNamSaga(),
    menuServiceSaga(),
    technologySaga(),
    trainingSaga(),
    redirectSaga(),
    floatingBannerSaga()
  ]);
}
