import { PageResponse } from 'services/common';

export const ERROR_SYSTEM = 'ERROR_SYSTEM';
export const PAGE = 'PAGE';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_NOT_FOUND = 'PAGE_NOT_FOUND';
export const PAGE_CLEAR_NOT_FOUND = 'PAGE_CLEAR_NOT_FOUND';

export interface PageStateType {
  [slug: string]: PageResponse;
}

export interface CommonStateType {
  isErrorSystem: boolean;
  pages: PageStateType;
  isNotFound: boolean;
}

export type ActionType = {
  type: typeof ERROR_SYSTEM | typeof PAGE_SUCCESS | typeof PAGE_CLEAR_NOT_FOUND | typeof PAGE_NOT_FOUND;
  payload?: { page: PageStateType };
};
