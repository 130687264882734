import produce from 'immer';
import { ActionType, FloatingBannerStateType, GET_FLOATING_BANNER_SUCCESS, SET_TOGGLE_FLOATING_BANNER } from 'store/types/floatingBanner';

const initialSate: FloatingBannerStateType = {
  data: undefined,
  loaded: false,
  isOpen: true
};

export const floatingBannerReducer = produce((draft: FloatingBannerStateType = initialSate, action: ActionType) => {
  if (action.type === GET_FLOATING_BANNER_SUCCESS) {
    draft = {
      data: action.payload,
      loaded: true,
      isOpen: true
    };
    return draft;
  }
  if (action.type === SET_TOGGLE_FLOATING_BANNER) {
    draft = {
      ...draft,
      isOpen: !draft.isOpen
    };
    return draft;
  }
  return draft;
});
