import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import bundledResources from 'utils/localTranslation';
import { CONSTANT_LANGUAGE_LIST, LOCAL_STORAGE } from 'utils/constants';

export function initLanguage() {
  const { pathname } = window.location;
  const lang = pathname.split('/')[1];
  if (lang && CONSTANT_LANGUAGE_LIST.includes(lang)) {
    window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lang);
    return lang;
  }
  window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, 'vi');
  return 'vi';
}

export const updateLanguageFromUrl = (url: string): string => {
  const paths = url.split('/');
  const lang = paths.find(item => CONSTANT_LANGUAGE_LIST.includes(item));
  if (lang) {
    window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lang);
    return lang;
  }
  window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, 'vi');
  return 'vi';
};

export const getCurrentLanguage = () => i18n.language || window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE) || 'vi';

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    fallbackLng: 'vi',
    lng: initLanguage(), // Detect not work, if lng turn on
    interpolation: {
      escapeValue: false,
      formatSeparator: '.',
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_BASE_URL}/systems/fe-translations`,
      customHeaders: {
        locale: window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE) || 'vi',
      },
    },
    ns: ['translation', 'local'],
    //! Only turn this defaultNS on when testing local static translation files
    // defaultNS: 'local',
  });

// eslint-disable-next-line no-restricted-syntax
for (const lang of Object.keys(bundledResources)) {
  i18n.addResources(lang, 'local', bundledResources[lang]);
}

//! Only turn this function on when testing static translation files
// eslint-disable-next-line no-restricted-syntax
// for (const lang of Object.keys(staticBundledResources)) {
//   i18n.addResourceBundle(lang, 'local', staticBundledResources[lang]);
// }

export default i18n;
