import http from 'services/http';
import { NewsResponse } from 'services/discovery/news';
import { GuidesResponse } from 'services/discovery/guides';

export interface HomePageType {
  id: number;
  name: string;
  title: string;
  isHomepage: number;
  pageMetaData: {
    homeSectionMainSliders0: {
      items: {
        [key: number]: {
          image: string;
          tabletImage: string;
          mobileImage: string;
          url: string;
        };
      };
    };
    homeSectionAbout0: {
      items: {
        [key: number]: {
          image: string;
          link: {
            text: string;
            link: string;
          };
        };
      };
    };
    homeSectionSecondSliders0: {
      items: {
        [key: number]: {
          bgImage: string;
          tabletBgImage: string;
          mobileBgImage: string;
          image: string;
          title: string;
          content: string;
          toggle: string;
          link: {
            text: string;
            link: string;
          };
          logo: string | null;
        };
      };
    };
    homeSectionIntroduction0: {
      bgImage: string;
      title: string;
      content: string;
      items: {
        [key: number]: {
          image: string;
          url: string;
        };
      };
      link: {
        text: string;
        link: string;
      };
    };
    homeSectionNews0: {
      heading: string;
      description: string;
    };
    homeSectionGuide0: {
      heading: string;
      description: string;
    };
    homeSectionSuport0: {
      heading: string;
      description: string;
      hotlineTitle: string;
      hotlineImage: string;
      hotlineLink: {
        text: string;
        link: string;
      };
      hotlineDescription: string;
      hotlineItems: {
        [key: number]: {
          phone: {
            text: string;
            link: string;
          };
        };
      };
      errorCodeTitle: string;
      errorCodeImage: string;
      errorCodeLink: {
        text: string;
        link: string;
      };
      errorCodeDescription: string;
      maintenanceServiceTitle: string;
      maintenanceServiceImage: string;
      maintenanceServiceLink: {
        text: string;
        link: string;
      };
      maintenanceServiceDescription: string;
      sparePartsTitle: string;
      sparePartsImage: string;
      sparePartsLink: {
        text: string;
        link: string;
      };
      sparePartsDescription: string;
    };
  };
}

export interface HomePageState {
  data: HomePageType;
  message: string;
  success: boolean;
}

export interface HomeStateTypes {
  news?: NewsResponse;
  guides?: GuidesResponse;
  loaded?: boolean;
  newsLoaded?: boolean;
  guildLoaded?: boolean;
}

export const getHome = async (): Promise<HomePageState> => {
  const { data } = await http.get('/home-page', { shouldCheckErrorPage: true });
  return data;
};
