import { createBrowserHistory } from 'history';
import { LOCAL_STORAGE } from 'utils/constants';
const history = createBrowserHistory();

// Change the language
export function changeLanguage(lng: string) {
  const { urltranslations } = history.location.state || {};
  if (urltranslations) {
    window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lng);
    window.location.href = urltranslations[lng];
  } else {
    window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lng);
    window.location.href = lng === 'vi' ? '/' : `/${lng}`;
  }
}

type Translations = Array<{
  locale: string;
  slug: string | null;
}>;

// Set the `urlTranslations` to the `state` of the `history`
export function setStateHistory(translations: Translations) {
  const hasNoTranslation = translations.some(translation => translation.slug === null);
  const urltranslations = hasNoTranslation
    ? {
        en: '/en',
        vi: '/',
      }
    : translations.reduce(
        (prev, next) => ({
          ...prev,
          [next.locale]: (next.locale === 'vi' ? '' : `/${next.locale}`) + '/' + next.slug,
        }),
        {}
      );

  history.location.state = {
    ...history.location.state,
    urltranslations,
  };
}

export default history;
