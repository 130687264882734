import { MenuItemType } from 'services/header';
import { BreadcrumbItemType, BreadcrumbsType } from 'services/common';
import { getPath } from 'lib/pathes';
import { NewsCategoryPrefix, GuidesCategoryPrefix } from 'constants/pathes';

// Toggle the menu content's behaviors
export const onToggleMenuContent = (element: HTMLElement) => {
  if (element) {
    const contentDisplayValue = element.style.display;
    element.style.pointerEvents = 'none';
    element.style.display = 'none';
    setTimeout(() => {
      element.style.pointerEvents = 'inherit';
      element.style.display = contentDisplayValue;
    }, 100);
  }
};

// Get the link of the menu item through MenuItem and ParentMenuItem
export function getMenuLink(menu: MenuItemType, lang: string, parentPage?: MenuItemType) {
  const languagePath = lang === 'vi' || menu.link?.includes('http') || menu.link?.includes('tel:+') ? '' : `/${lang}`;

  switch (menu.type) {
    case 'custom_link':
      return languagePath + menu.link;
    case 'OneContent\\Page\\Models\\Page':
      return languagePath + '/' + menu.reference?.slug;
    case 'OneContent\\Product\\Models\\ProductType':
      return languagePath + '/' + parentPage?.reference?.slug + '/' + menu.reference?.slug;
    default:
      return languagePath + '/' + parentPage?.reference?.slug + '/' + menu.reference?.slug;
  }
}

export interface BreadcrumbCustomType {
  title: string;
  link?: string;
  state?: { categorySlug: string };
}

// Get the list of the breadcrumb custom
export function getBreadcrumbs(breadcrumbs: BreadcrumbsType, title: string, lang: string): Array<BreadcrumbCustomType> {
  const languagePath = lang === 'vi' ? '' : `/${lang}`;
  let breadcrumbsCustom: Array<BreadcrumbCustomType> = [];

  if (breadcrumbs && breadcrumbs.length > 0) {
    // NOTE: The main breadcrumb page is at the last page level and it is always at the bottom of the breadcrumb list
    let mainBreadcrumb: BreadcrumbItemType;
    for (const breadcrumb of breadcrumbs) {
      if (breadcrumb.type === 'OneContent\\Page\\Models\\Page') {
        mainBreadcrumb = breadcrumb;
      }
    }
    breadcrumbsCustom = breadcrumbs.map(breadcrumb => {
      switch (breadcrumb.type) {
        case 'OneContent\\Page\\Models\\Page':
          return { title: breadcrumb.text, link: languagePath + '/' + breadcrumb.slug };
        case 'OneContent\\Product\\Models\\Series': {
          const productType = breadcrumbs.find(({ type }) => type === 'OneContent\\Product\\Models\\ProductType');
          return {
            title: breadcrumb.text,
            link:
              languagePath +
              '/' +
              mainBreadcrumb.slug +
              '/' +
              (productType ? productType?.slug + '/' : '') +
              breadcrumb.slug,
          };
        }
        case 'OneContent\\News\\Models\\NewsCategory': {
          return {
            title: breadcrumb.text,
            link:
              languagePath + '/' + NewsCategoryPrefix[lang as keyof typeof NewsCategoryPrefix] + '/' + breadcrumb.slug,
          };
        }
        case 'OneContent\\Guide\\Models\\GuideCategory': {
          return {
            title: breadcrumb.text,
            link:
              languagePath +
              '/' +
              GuidesCategoryPrefix[lang as keyof typeof GuidesCategoryPrefix] +
              '/' +
              breadcrumb.slug,
          };
        }
        case 'OneContent\\CaseStudy\\Models\\CaseStudyCategory':
          return {
            title: breadcrumb.text,
            link: languagePath + '/' + mainBreadcrumb.slug,
            state: { categorySlug: breadcrumb.slug },
          };
        default:
          return { title: breadcrumb.text, link: languagePath + '/' + mainBreadcrumb.slug + '/' + breadcrumb.slug };
      }
    });
  }

  breadcrumbsCustom.unshift({ title: lang === 'vi' ? 'Trang chủ' : 'Home', link: getPath('APP') });
  breadcrumbsCustom.push({ title });

  return breadcrumbsCustom;
}
