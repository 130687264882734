import http from 'services/http';

export interface PichonkunCategoriesResponse {
  success: boolean;
  message: string;
  data: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
}

export interface PichonkunResponse {
  success: boolean;
  message: string;
  meta: {
    limit: number;
    page: number;
    total: number;
    totalPages: number;
  };
  data: Array<{
    id: number;
    name: string;
    downloadText: string;
    thumbnail: string;
    file: string;
  }>;
}

export interface PichonkunPageType {
  title: string;
  slug: string;
  name: string;
  pageMetaData: {
    bannerTagTitleDescriptionImage0: {
      bgImage: string;
      mobileBgImage: string;
      tabletBgImage: string;
      description: string;
      heading: string;
      tag: string;
    };
    itemImageDescription0: {
      contentItem: string;
      imageItem: string;
    };
    pichonkunSectionDisclaimer0: {
      description: string;
      heading: string;
    };
  };
}

export const getPichonkunCategoriesList = async (): Promise<PichonkunCategoriesResponse> => {
  const { data } = await http.get('/pichonkun-categories');
  return data;
};

export const getPichonkunsList = async ({
  category_ids,
  page = 1,
}: {
  category_ids: number;
  page?: number;
}): Promise<PichonkunResponse> => {
  const { data } = await http.get('/pichonkuns', {
    params: {
      limit: 6,
      page,
      category_ids,
    },
  });
  return data;
};
