import { MenuServicesResponse } from 'services/services';

export const GET_MENU_SERVICES = 'GET_MENU_SERVICES';
export const GET_MENU_SERVICES_SUCCESS = 'GET_MENU_SERVICES_SUCCESS';

export interface MenuServiceState extends MenuServicesResponse {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_MENU_SERVICES_SUCCESS;
  payload: MenuServicesResponse;
};
