import http from 'services/http';
import { CancelTokenSource } from 'axios';

export interface KeywordsType {
  message: string;
  success: boolean;
  data: Array<{
    searchKeyword: string;
    id: number;
  }>;
}

export interface SearchServiceType {
  message: string;
  success: boolean;
  data: Array<{
    id: number;
    title: string;
    content: string;
    slug: string;
  }>;
}

export type SearchResultTableNameType = 'faqs' | 'guides' | 'solutions' | 'case_studies';

export interface SearchOtherType {
  message: string;
  success: boolean;
  data: Array<{
    resultDescription: string;
    resultSlug: string;
    resultTableName: SearchResultTableNameType;
    resultTitle: string;
  }>;
  meta: {
    limit: number;
    page: number;
    total: number;
    totalPages: number;
  };
}

export interface SearchPageType {
  title: string;
  slug: string;
  pageMetaData: {
    searchSectionSearch0: {
      heading: string;
      placeholderSearch: string;
    };
  };
}
export interface SuggestionType {
  groupName: string;
  data: {
    name?: string;
    question?: string;
    title?: string;
  }[];
}

export interface SearchSuggestionType {
  data: SuggestionType[];
}

export interface ServiceSuggestionType {
  data: SuggestionType;
}

export type FetchOtherRequest = {
  page: number;
  limit: number;
  keyword?: string;
};

type FetchSuggestionRequest = {
  limit?: number;
  keyword?: string;
};

type FetchServicesRequest = {
  keyword?: string;
  type?: number;
};

export const getKeyword = async (): Promise<KeywordsType> => {
  const res = await http.get('/search-trackings');
  return res.data;
};

export const storeKeyword = async (keyword: string) => {
  const { data } = await http.post('/search-trackings', { keyword });
  return data;
};

export const getSearchService = async (
  params: FetchServicesRequest,
  cancelTokenSource?: CancelTokenSource | null
): Promise<SearchServiceType | ServiceSuggestionType | undefined> => {
  try {
    const res = await http.get('/service-pages', { params, cancelToken: cancelTokenSource?.token });
    return res.data;
  } catch {
    // TODO: Error handler
  }
};

export const getSearchOther = async (params: FetchOtherRequest): Promise<SearchOtherType> => {
  const res = await http.get('/search-other', {
    params,
  });
  return res.data;
};

export const getSearchSuggestion = async (
  params: FetchSuggestionRequest,
  cancelTokenSource: CancelTokenSource
): Promise<SearchSuggestionType | undefined> => {
  try {
    const res = await http.get('/search-suggestion', { params, cancelToken: cancelTokenSource.token });
    return res.data;
  } catch {
    // TODO: Error handler
  }
};
