import http from 'services/http';

export interface TrainingMenuType {
  title: string;
  reference: {
    slug: string;
  };
  link: string;
  target: string;
}

export interface TrainingMenuResponse {
  data: {
    [key: number]: TrainingMenuType;
  };
  message: string;
  success: boolean;
}

export const getTrainingServices = async (): Promise<TrainingMenuResponse> => {
  const { data } = await http.get('/get-menu/training_menu');
  return data;
};
