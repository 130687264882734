import { GuidesResponse, CategoriesResponse } from 'services/discovery/guides';

export const GUIDES_LIST = 'GUIDES_LIST';
export const GUIDES_LIST_SUCCESS = 'GUIDES_LIST_SUCCESS';
export const GUIDES_PAGE = 'GUIDES_PAGE';
export const GUIDES_PAGE_SUCCESS = 'GUIDES_PAGE_SUCCESS';

export interface GuidesPageStateType {
  categories: CategoriesResponse;
  guides: {
    [key: string]: GuidesResponse;
  };
}

export interface GuidesStateType extends GuidesPageStateType {
  loaded: boolean;
  isLoadingGuide?: boolean;
}

export type ActionType = {
  type: typeof GUIDES_PAGE_SUCCESS | typeof GUIDES_LIST_SUCCESS | typeof GUIDES_LIST;
  payload: { data: GuidesPageStateType | GuidesResponse; category?: string };
};
