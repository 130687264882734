import produce from 'immer';
import { NewsResponse } from 'services/discovery/news';
import {
  NEWS_LIST_SUCCESS,
  NEWS_PAGE_SUCCESS,
  NewsPageStateType,
  NewsStateType,
  ActionType,
  NEWS_LIST,
  // SIX_OPTIONS_PAGE_SUCCESS
} from 'store/types/discovery/news';

const initialState: NewsStateType = {
  categories: {
    data: [],
    message: '',
    success: false,
  },
  news: {},
  // sixOptions: {},
  loaded: false,
  isLoadingNews: false,
};

export const newsReducer = produce((draft: NewsStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case NEWS_LIST:
      draft = {
        ...draft,
        isLoadingNews: true,
      };
      return draft;
    case NEWS_PAGE_SUCCESS:
      draft = {
        ...(action.payload.data as NewsPageStateType),
        news: {
          ...draft.news,
          ...(action.payload.data as NewsPageStateType).news,
        },
        loaded: true,
      };
      return draft;
    // case SIX_OPTIONS_PAGE_SUCCESS:
    //   draft = {
    //     ...(action.payload.data as NewsPageStateType),
    //     sixOptions: {
    //       ...draft.news,
    //       ...(action.payload.data as NewsPageStateType).news,
    //     },
    //     loaded: true,
    //   };
    //   return draft;
    case NEWS_LIST_SUCCESS:
      let newsData;
      if ((action.payload.data as NewsResponse).meta.page === 1) {
        draft.news[action.payload.category as string] = (action.payload.data as NewsResponse);
        newsData = draft;
      } else {
        draft.news[action.payload.category as string] = draft.news[action.payload.category as string]
          ? {
            ...(action.payload.data as NewsResponse),
            data: [
              ...draft.news[action.payload.category as string].data,
              ...(action.payload.data as NewsResponse).data,
            ],
          }
          : (action.payload.data as NewsResponse);
        newsData = draft;
      }

      newsData.isLoadingNews = false;

      return newsData;
    default:
      const newDraft = { ...draft };
      newDraft.isLoadingNews = false;
      return newDraft;
  }
});
