import http from 'services/http';
import { CaseStudyType } from 'services/discovery/casestudy';
import { MetaDataType, BreadcrumbsType } from 'services/common';
export interface SolutionType {
  id: number;
  thumbnail: string;
  name: string;
  slug: string;
  showInSmartAcPage: boolean;
  showInProductPage: boolean;
  isOverlay: boolean;
}

export interface SolutionsResponse {
  data: Array<SolutionType>;
  meta: {
    totalPages: number;
    limit: number;
    total: number;
    page: number;
  };
  success: boolean;
  message: string;
}

export interface ParadigmType {
  name: string;
  title: string;
  point: {
    x: string;
    y: string;
  };
  blocks: Array<{
    title: string;
    strength: string;
    weakness: string;
    largeImage: string;
    smallImage: string;
    isRecommended: boolean;
  }>;
  isRecommended: boolean;
}

export interface SolutionSlugType {
  success: boolean;
  message: string;
  data: {
    data: {
      topBanner: {
        description: string;
        imageMobile: string;
        imageTablet: string;
        image: string;
        tag: string;
        title: string;
      };
      middleBanner: {
        items: Array<{
          bgImage: string;
          bgImageMobile: string;
          bgImageTablet: string;
          buttonLink: string;
          buttonText: string;
          description: string;
          logo: string | null;
          title: string;
        }>;
      };
      benefits: Array<{
        id: number;
        icon: string;
        name: string;
        description: string;
      }>;
      paradigm: {
        image: string;
        items: Array<ParadigmType>;
        type: 'room' | 'product';
      };
      contactDaikin: {
        image: string;
        title: string;
        linkUrl: string;
        linkText: string;
      };
      recommendations: Array<{
        id: number;
        thumbnail: null | string;
        name: string;
        link: string;
        strengths: Array<{
          content: string;
        }>;
        weaknesses: Array<{
          content: string;
        }>;
        productType: {
          slug: string;
        } | null;
      }>;
      caseStudies: Array<CaseStudyType>;
      translations: Array<{ locale: string; slug: string | null }>;
      name: string;
      isOverlay: number;
    };
    metaData: MetaDataType;
    breadcrumbs: BreadcrumbsType;
  };
}

export interface SolutionPageType {
  name: string;
  title: string;
  slug: string;
  pageMetaData: {
    solutionListSectionListSolution0: {
      description: string;
      heading: string;
    };
  };
}

export type SolutionRequest = {
  page: number;
  limit?: number;
  show_in_product_page?: boolean;
  show_in_smart_ac_page?: boolean;
  category_id?: number;
};

export interface CategorySolutionType {
  id: number;
  displayOrder: number;
  name: string;
  slug: string;
  locale: string;
  imageButton: {
    active: string;
    inactive: string;
  };
}

export interface CategorySolutionsResponse {
  data: Array<CategorySolutionType>;
  meta: {
    totalPages: number;
    limit: number;
    total: number;
    page: number;
  };
  success: boolean;
  message: string;
}

export interface CategorySolutionSlugType {
  success: boolean;
  message: string;
  data: {
    name: string;
    slug: string;
    translations: Array<{
      id: number;
      name: string;
      slug: string;
      locale: string;
      solutionCategoryId: number;
    }>;
    metaData: MetaDataType;
    breadcrumbs: BreadcrumbsType;
  };
}

export const getSolution = async (params?: SolutionRequest): Promise<SolutionsResponse> => {
  const { data } = await http.get('/solutions', {
    params: {
      ...params,
      page: params?.page || 1,
      limit: params?.limit || 6,
    },
  });
  return data;
};

export const getSolutionSlug = async (slug: string): Promise<SolutionSlugType> => {
  const { data } = await http.get(`/solutions/slug/${slug}`, { shouldCheckErrorPage: true });
  return data;
};

export const getCategoriesSolution = async (params?: SolutionRequest): Promise<CategorySolutionsResponse> => {
  const { data } = await http.get('/solution-categories', {
    params: {
      ...params,
      page: params?.page || 1,
    },
  });
  return data;
};

export const getCategoriesSolutionId = async (id: number): Promise<CategorySolutionSlugType> => {
  const { data } = await http.get(`/solution-categories/${id}`, { shouldCheckErrorPage: true });
  return data;
};
