import { GroupType } from 'react-select';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getCatalogueList } from 'services/services/catalogue';
import { CatalogueStateType, GET_CATALOGUE_BY_IDS } from 'store/types/services/catalogue';
import {
  GET_CATALOGUE_SUCCESS,
  GET_CATALOGUE,
  GET_CATALOGUE_MORE_SUCCESS,
  GET_CATALOGUE_MORE,
} from 'store/types/services/catalogue';
import { OptionType } from 'components/molecules/downloadselect';

function* catalogue() {
  const catalogueList = yield call(getCatalogueList, { page: 1, limit: 12 });
  yield put({ type: GET_CATALOGUE_SUCCESS, payload: { catalogueList } });
}

function* catalogueByIds(action: { type: string; payload: { product_type_ids: Array<GroupType<OptionType>> } }) {
  const catalogueState = (yield select(state => state.catalogue)) as CatalogueStateType;
  const catalogueList = yield call(getCatalogueList, {
    page: 1,
    limit: catalogueState.catalogueList.meta.limit,
    product_type_ids:
      action.payload.product_type_ids.length > 0
        ? action.payload.product_type_ids.map(option => option.value).join()
        : undefined,
  });

  yield put({
    type: GET_CATALOGUE_SUCCESS,
    payload: { catalogueList, optionsSelected: action.payload.product_type_ids },
  });
}

function* catalogueMore() {
  const catalogueState = (yield select(state => state.catalogue)) as CatalogueStateType;
  const catalogueList = yield call(getCatalogueList, {
    page: catalogueState.catalogueList.meta.page + 1,
    limit: catalogueState.catalogueList.meta.limit,
    product_type_ids: catalogueState.optionsSelected?.map(option => option.value).join(),
  });
  yield put({ type: GET_CATALOGUE_MORE_SUCCESS, payload: { catalogueList } });
}

function* watchCatalogue() {
  yield takeEvery(GET_CATALOGUE, catalogue);
}

function* watchCatalogueByIds() {
  yield takeEvery(GET_CATALOGUE_BY_IDS, catalogueByIds);
}

function* watchCatalogueMore() {
  yield takeEvery(GET_CATALOGUE_MORE, catalogueMore);
}

export default function* catalogueSaga() {
  yield all([watchCatalogue(), watchCatalogueMore(), watchCatalogueByIds()]);
}
