import produce from 'immer';
import { HomeStateTypes } from 'services/home_page';
import { HOME_SUCCESS, HOME_STATIC, HOME_GUILD_SUCCESS, HOME_NEWS_SUCCESS, ActionType } from 'store/types/home_page';

const initialSate: HomeStateTypes = {
  loaded: false,
  guildLoaded: false,
  newsLoaded: false,
};

export const homeReducer = produce((draft: HomeStateTypes = initialSate, action: ActionType) => {
  switch (action.type) {
    case HOME_STATIC:
      draft = {
        ...draft,
        loaded: true,
      };
      return draft;
    case HOME_SUCCESS:
      draft = {
        ...draft,
        news: { ...action.payload.newsResponse },
        guides: { ...action.payload.guidesResponse },
        loaded: true,
      };
      return draft;
    case HOME_GUILD_SUCCESS:
      draft = {
        ...draft,
        guides: { ...action.payload.guidesResponse },
        guildLoaded: true,
      };
      return draft;
    case HOME_NEWS_SUCCESS:
      draft = {
        ...draft,
        news: { ...action.payload.newsResponse },
        newsLoaded: true,
      };
      return draft;
    default:
      return draft;
  }
});
