import produce from 'immer';
import { SystemConfigStateType, SYSTEM_CONFIG_SUCCESS, ActionType } from 'store/types/system';

const initialState: SystemConfigStateType = {
  loaded: false,
};

export const systemConfigReducer = produce((draft: SystemConfigStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case SYSTEM_CONFIG_SUCCESS:
      draft.system = action.payload;
      draft.loaded = true;
      return draft;
    default:
      return draft;
  }
});
