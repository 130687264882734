import React from 'react';
import { mapModifiers } from 'lib/component';

type Modifier = 'transparent' | 'oversize' | 'center' | 'circled';

interface ImageProps {
  modifiers?: Modifier | Modifier[];
  src: string;
  alt: string;
  lazy?: boolean;
  aspectRatio?:
    | '16x9'
    | '1x1'
    | '2x1'
    | '3x2'
    | '3x4'
    | '4x3'
    | 'carddetail'
    | 'carddetailnews'
    | 'carddetailnewsrelative'
    | 'cardsupport'
    | 'carddiscover'
    | 'cardcasestudy'
    | 'cardtaglarge'
    | 'cardtagmedium'
    | 'cardtagsmall'
    | 'cardcomparehorizontal'
    | 'cardcomparevertical'
    | 'cardsystem'
    | 'cardproducttype'
    | 'cardproducttypemain'
    | 'carddownload'
    | 'cardpartner'
    | 'cardpichonkun'
    | 'cardaward'
    | 'cardbusiness'
    | 'productresidential'
    | 'productindustrial'
    | 'productcomercial'
    | 'cardthumbfeature'
    | 'cardconstruct'
    | 'cardcontact'
    | 'cardaboutdaikin'
    | 'cardfactoryab'
    | 'cardfactoryc'
    | 'cardfactoryg'
    | 'cardtag'
    | 'cardsolution'
    | 'cardthumbsolution'
    | 'imagesolution'
    | 'imagesolutionproducttype'
    | 'imageselector'
    | 'cardcompareoption'
    | 'comparisonbar'
    | 'productsolutions'
    | 'airpurifierpartner'
    | 'technologystreamer'
    | 'airpurifiermart'
    | 'modalbackgroundimage';
}

export const Image: React.FC<ImageProps> = ({ src, alt, aspectRatio, modifiers, lazy }) => (
  <div className={mapModifiers('a-image', aspectRatio, aspectRatio ? 'fixed' : undefined, modifiers)}>
    <img className="a-image_img" loading={lazy ? 'lazy' : undefined} src={src} alt={alt} />
  </div>
);

Image.defaultProps = {
  lazy: true,
};
