import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_MENU_DAIKIN_VIETNAM, GET_MENU_DAIKIN_VIETNAM_SUCCESS } from 'store/types/about_us/daikin_vietnam';
import { getMenuDaikinVietnam } from 'services/about_us/daikin_vietnam';

function* menuDaikinVietname() {
  const res = yield call(getMenuDaikinVietnam);
  yield put({ type: GET_MENU_DAIKIN_VIETNAM_SUCCESS, payload: res });
}

function* watchMenuDaikinVietname() {
  yield takeLatest(GET_MENU_DAIKIN_VIETNAM, menuDaikinVietname);
}

export default function* daikinVietNamSaga() {
  yield all([watchMenuDaikinVietname()]);
}
