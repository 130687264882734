import { PichonkunCategoriesResponse, PichonkunResponse } from 'services/discovery/pichonkun';

export const PICHONKUN_PAGE = 'PICHONKUN_PAGE';
export const PICHONKUN_PAGE_SUCCESS = 'PICHONKUN_PAGE_SUCCESS';
export const PICHONKUN_LIST = 'PICHONKUN_LIST';
export const PICHONKUN_LIST_SUCCESS = 'PICHONKUN_LIST_SUCCESS';

export interface PichonkunPageType {
  categories: PichonkunCategoriesResponse;
  listPichonkun: {
    [key: number]: PichonkunResponse;
  };
}

export interface PichonkunPageStateType extends PichonkunPageType {
  loaded: boolean;
}

export type ActionType = {
  type: typeof PICHONKUN_PAGE_SUCCESS | typeof PICHONKUN_LIST_SUCCESS;
  payload: { data: PichonkunPageStateType | PichonkunResponse; category?: number };
};
