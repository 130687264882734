import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getContactTypeList } from 'services/contact';
import { GET_CONTACT, GET_CONTACT_SUCCESS } from 'store/types/contact';

function* contact() {
  const contactTypeRes = yield call(getContactTypeList);

  yield put({
    type: GET_CONTACT_SUCCESS,
    payload: contactTypeRes,
  });
}

function* watchContact() {
  yield takeEvery(GET_CONTACT, contact);
}

export default function* contactSaga() {
  yield all([watchContact()]);
}
