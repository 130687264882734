import produce from 'immer';
import { GET_MENU_SERVICES_SUCCESS, ActionType, MenuServiceState } from 'store/types/services';

const initialState: MenuServiceState = {
  message: '',
  success: false,
  data: {},
  loaded: false,
};

export const menuServiceReducer = produce((draft: MenuServiceState = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_MENU_SERVICES_SUCCESS:
      draft = {
        ...action.payload,
        loaded: true,
      };
      return draft;
    default:
      return draft;
  }
});
