import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  PICHONKUN_PAGE,
  PICHONKUN_PAGE_SUCCESS,
  PICHONKUN_LIST,
  PICHONKUN_LIST_SUCCESS,
} from 'store/types/discovery/pichonkun/index';
import {
  getPichonkunCategoriesList,
  getPichonkunsList,
  PichonkunResponse,
  PichonkunCategoriesResponse,
} from 'services/discovery/pichonkun';

function* getPagePichonkun() {
  const categoriesRes = (yield call(getPichonkunCategoriesList)) as PichonkunCategoriesResponse;

  const listRes = (yield all(
    categoriesRes.data.map(({ id }) => call(getPichonkunsList, { category_ids: id }))
  )) as Array<PichonkunResponse>;

  const pichonkunListCustom = yield listRes.reduce(
    (prev, next, index) => ({ ...prev, [categoriesRes.data[index].id]: next }),
    {}
  );

  yield put({
    type: PICHONKUN_PAGE_SUCCESS,
    payload: {
      data: {
        categories: categoriesRes,
        listPichonkun: pichonkunListCustom,
      },
    },
  });
}

function* watchPagePichonkun() {
  yield takeEvery(PICHONKUN_PAGE, getPagePichonkun);
}

function* getListMore(action: { type: string; payload: { category_ids: number; page: number } }) {
  const data = yield call(getPichonkunsList, { category_ids: action.payload.category_ids, page: action.payload.page });
  yield put({ type: PICHONKUN_LIST_SUCCESS, payload: { data, category: action.payload.category_ids } });
}

function* watchListMore() {
  yield takeEvery(PICHONKUN_LIST, getListMore);
}

export default function* pichonkunSaga() {
  yield all([watchPagePichonkun(), watchListMore()]);
}
