import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { GET_FAQ, GET_FAQ_SUCCESS, GET_FAQ_PAGE, GET_FAQ_PAGE_SUCCESS } from 'store/types/services/faq';
import { getFaqCategoryList, getFaqListByCategorySlug, FAQCategoriesResponse } from 'services/services/faq';
import { getGuidesList, GuidesResponse } from 'services/discovery/guides';

function* faqPage() {
  const [faqCategoriesResponse, relativeGuides] = (yield all([
    call(getFaqCategoryList),
    call(getGuidesList, { page: 1, limit: 7, show_in_faq_page: true }),
  ])) as Array<FAQCategoriesResponse | GuidesResponse>;

  yield put({
    type: GET_FAQ_PAGE_SUCCESS,
    payload: {
      data: {
        categories: faqCategoriesResponse,
        relativeGuides: relativeGuides,
      },
    },
  });
}

function* watchFaqPage() {
  yield takeLatest(GET_FAQ_PAGE, faqPage);
}

function* getFaqListCategory(action: { type: string; payload: { slug: string } }) {
  const data = yield call(getFaqListByCategorySlug, action.payload.slug);
  yield put({ type: GET_FAQ_SUCCESS, payload: { data, faqCategory: action.payload.slug } });
}

function* watchFaqListCategory() {
  yield takeEvery(GET_FAQ, getFaqListCategory);
}

export default function* faqSaga() {
  yield all([watchFaqPage(), watchFaqListCategory()]);
}
