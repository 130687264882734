import { NewsResponse, CategoriesResponse } from 'services/discovery/news';

export const NEWS_PAGE = 'NEWS_PAGE';
export const NEWS_PAGE_SUCCESS = 'NEWS_PAGE_SUCCESS';
export const NEWS_LIST = 'NEWS_LIST';
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS';

// export const SIX_OPTIONS_PAGE = 'SIX_OPTIONS_PAGE';
// export const SIX_OPTIONS_PAGE_SUCCESS = 'SIX_OPTIONS_PAGE_SUCCESS';

export interface NewsPageStateType {
  categories: CategoriesResponse;
  news: {
    [key: string]: NewsResponse;
  };
  // sixOptions: {
  //   [key: string]: NewsResponse;
  // }
}

export interface NewsStateType extends NewsPageStateType {
  loaded: boolean;
  isLoadingNews?: boolean;
}

export type ActionType = {
  type: typeof NEWS_PAGE_SUCCESS | typeof NEWS_LIST_SUCCESS | typeof NEWS_LIST;
  payload: { data: NewsPageStateType | NewsResponse; category?: string };
};
