import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getTechnologyBenefitMenu } from 'services/discovery/technology';
import { TECHNOLOGY_MENU, TECHNOLOGY_MENU_SUCCESS } from 'store/types/discovery/technology';

function* technologyBenefitMenu() {
  const res = yield call(getTechnologyBenefitMenu);
  yield put({ type: TECHNOLOGY_MENU_SUCCESS, payload: res });
}

function* watchTechnologyBenefitMenu() {
  yield takeEvery(TECHNOLOGY_MENU, technologyBenefitMenu);
}

export default function* technologyBenefitSaga() {
  yield all([watchTechnologyBenefitMenu()]);
}
