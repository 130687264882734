import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CategoriesResponse,
  getCategoriesNews,
  getNewsByCategory,
  getNewsList,
  NewsResponse,
  TypeCategoryNews,
} from 'services/discovery/news';
import { NEWS_LIST, NEWS_LIST_SUCCESS, NEWS_PAGE, NEWS_PAGE_SUCCESS } from 'store/types/discovery/news';

function* newsPage(action: { type: string; payload: { page: number; limit: number, category_type: string, type: TypeCategoryNews } }) {
  const [categoriesNewsResponse] = (yield all([
    call(getCategoriesNews, { type: action.payload.type }),
    // call(getNewsList, { page: 1, limit: 7 }),
  ])) as Array<CategoriesResponse | NewsResponse>;


  const categoryIds = (categoriesNewsResponse?.data as any)?.map((category: any) => category.id);
  if (action.payload.category_type !== 'all') {
    const data = yield call(getNewsByCategory, action.payload.category_type);
    yield put({ type: NEWS_LIST_SUCCESS, payload: { data, category: action.payload.category_type } });
  } else {
    const data = yield call(getNewsList, { page: action.payload.page, limit: action.payload.limit, category_ids: categoryIds.join(',') });
    yield put({
      type: NEWS_LIST_SUCCESS,
      payload: { data, category: action.payload.category_type }
    });
  }

  yield put({
    type: NEWS_PAGE_SUCCESS,
    payload: {
      data: {
        categories: categoriesNewsResponse,
      },
    },
  });
}

function* watchNewsPage() {
  yield takeLatest(NEWS_PAGE, newsPage);
}

function* news(action: { type: string; payload: { category_type: string; page: number, limit: 7 } }) {
  if (action.payload.category_type !== 'all') {
    const data = yield call(getNewsByCategory, action.payload.category_type);
    yield put({ type: NEWS_LIST_SUCCESS, payload: { data, category: action.payload.category_type } });
  } else {
    const data = yield call(getNewsList, { page: action.payload.page, limit: action.payload.limit });
    yield put({ type: NEWS_LIST_SUCCESS, payload: { data, category: action.payload.category_type } });
  }
}

function* watchNews() {
  yield takeLatest(NEWS_LIST, news);
}

export default function* newsSaga() {
  yield all([watchNewsPage(), watchNews()]);
}
