import { GuidesResponse } from 'services/discovery/guides';
import { FAQCategoriesResponse, FAQListByCategorySlugResponse } from 'services/services/faq';

export const GET_FAQ_PAGE = 'GET_CATEGORIES';
export const GET_FAQ_PAGE_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';

export interface FaqPageCombineType {
  categories: FAQCategoriesResponse;
  contents: {
    [key: string]: FAQListByCategorySlugResponse;
  };
  relativeGuides: GuidesResponse;
}

export interface FaqPageStateType extends FaqPageCombineType {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_FAQ_PAGE_SUCCESS | typeof GET_FAQ_SUCCESS;
  payload: {
    data: FaqPageCombineType | FAQListByCategorySlugResponse;
    faqCategory?: string;
    relativeGuides: GuidesResponse;
  };
};
