import React, { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { SystemConfigItemContentType } from 'services/system';

interface Props {
  scriptGoogleTagManager: SystemConfigItemContentType;
}

export const GoogleTagManager: React.FC<Props> = ({ scriptGoogleTagManager }) => {
  const initializedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!initializedRef.current && scriptGoogleTagManager && Array.isArray(scriptGoogleTagManager.content)) {
      scriptGoogleTagManager.content.forEach(item => {
        TagManager.initialize({
          gtmId: item,
        });
      });
      initializedRef.current = true;
    }
  }, [scriptGoogleTagManager]);

  return null;
};
