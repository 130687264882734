import React from 'react';
import { mapModifiers } from 'lib/component';

type Modifier =
  | 'center'
  | 'bold'
  | 'normal'
  | 'white'
  | 'cerulean'
  | 'blacktwo'
  | 'medium'
  | 'brownishgrey'
  | 'warmgrey'
  | 'greyish'
  | 'brownishgrey-op-05'
  | 'fs-16';

interface Props {
  modifiers?: Modifier | Modifier[];
}

export const Text: React.FC<Props> = props => {
  return <p className={mapModifiers('a-text', props.modifiers)}>{props.children}</p>;
};
