import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { SystemConfigItemContentType } from 'services/system';

interface Props {
  scriptGoogleAnalytics: SystemConfigItemContentType;
}

export const GoogleAnalytics: React.FC<Props> = ({ scriptGoogleAnalytics }) => {
  const location = useLocation();
  const initializedRef = useRef<boolean>(false);
  const trackingIdsRef = useRef<Array<string>>([]);

  useEffect(() => {
    if (!initializedRef.current && scriptGoogleAnalytics && Array.isArray(scriptGoogleAnalytics.content)) {
      const trackers = scriptGoogleAnalytics.content.map((id, index) => {
        const trackerName = `tracker${index + 1}`;
        trackingIdsRef.current[index] = trackerName;
        return {
          trackingId: id,
          gaOptions: { name: trackerName },
        };
      });
      ReactGA.initialize(trackers);
      initializedRef.current = true;
    }
  }, [scriptGoogleAnalytics]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `${location.pathname} + ${location.search}, ${trackingIdsRef.current}` });
  }, [location]);

  return null;
};
