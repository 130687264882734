import React, { useCallback, useRef } from 'react';
import { mapModifiers } from 'lib/component';

interface LangtoggleitemProps {
  title: string;
  value: string;
  active: boolean;
  onClick: () => void;
}

export const LanguageItem: React.FC<LangtoggleitemProps> = props => {
  const langRef = useRef<HTMLSpanElement | null>(null);

  const handleClick = useCallback(() => {
    props.onClick();
    if (langRef.current) {
      const langListElm = langRef.current.parentElement as HTMLElement;
      langListElm.style.pointerEvents = 'none';
      setTimeout(() => (langListElm.style.pointerEvents = 'inherit'), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      ref={langRef}
      className={mapModifiers('a-language_item', props.active ? 'active' : undefined)}
      onClick={handleClick}
      onTouchStart={handleClick}
    >
      {props.title}
    </span>
  );
};

interface LanguageProps {
  langActived: string;
  withDropdown?: boolean;
}

export const Language: React.FC<LanguageProps> = props => {
  return (
    <div className={mapModifiers('a-language', props.withDropdown && 'withdropdown')}>
      {props.withDropdown && <span className="a-language_langactived">{props.langActived}</span>}
      <div className="a-language_list">{props.children}</div>
    </div>
  );
};
