import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  // HOME,
  // HOME_SUCCESS,
  HOME_NEWS,
  HOME_NEWS_SUCCESS,
  HOME_GUILD,
  HOME_GUILD_SUCCESS,
} from 'store/types/home_page';
import { getNewsList } from 'services/discovery/news/index';
import { getGuidesList } from 'services/discovery/guides';

// function* home() {
//   const [newsResponse, guidesResponse] = (yield all([
//     call(getNewsList, { page: 1, limit: 7, is_home: true }),
//     call(getGuidesList, { page: 1, limit: 7, is_home: true }),
//   ])) as Array<NewsResponse | GuidesResponse>;

//   yield put({ type: HOME_SUCCESS, payload: { newsResponse, guidesResponse } });
// }

function* homeNews() {
  const newsResponse = yield call(getNewsList, { page: 1, limit: 7, is_home: true });

  yield put({ type: HOME_NEWS_SUCCESS, payload: { newsResponse } });
}

function* homeGuild() {
  const guidesResponse = yield call(getGuidesList, { page: 1, limit: 7, is_home: true });

  yield put({ type: HOME_GUILD_SUCCESS, payload: { guidesResponse } });
}

// function* watchHome() {
//   yield takeLatest(HOME, home);
// }

function* watchHomeNews() {
  yield takeEvery(HOME_NEWS, homeNews);
}

function* watchHomeGuild() {
  yield takeEvery(HOME_GUILD, homeGuild);
}

export default function* homeSaga() {
  yield all([watchHomeNews(), watchHomeGuild()]);
}
