import http from 'services/http';

export type ErrorCodesPageResponse = {
  message: string;
  success: boolean;
  data: {
    title: string;
    name: string;
    slug: string;
    pageMetaData: {
      errorCodesSectionErrorCodeSearch97: {
        heading: string;
        content: string;
        placeholderSearch: string;
        placeholderSearch2: string;
      };
      errorCodesSectionCheckErrorCodes98: {
        contentCheck: string;
        headingCheck: string;
      };
    };
  };
};

export interface ErrorCodePageType {
  title: string;
  slug: string;
  pageMetaData: {
    bannerTagTitleDescriptionImageSearch0: {
      tag: string;
      heading: string;
      description: string;
      bgImage: string;
      tabletBgImage: string;
      mobileBgImage: string;
      headingSearch: string;
      placeholderSearch: string;
      suggestSearch: string;
    };
    errorCodesSectionErrorCodeSearch0: {
      heading: string;
      content: string;
      placeholderSearch: string;
      placeholderSearch2: string;
    };
    errorCodesSectionCheckErrorCodes0: {
      contentCheck: string;
      headingCheck: string;
    };
    errorCodesSectionRelativeGuide0: {
      headingRelative: string;
      contentRelative: string;
    };
  };
}

export interface ErrorCodesType {
  id: number;
  code: string;
  title: string;
  descripton: string;
  parentId: string;
  children: Array<{
    id: number;
    code: string;
    title: string;
    description: string;
    productTypes: Array<{
      id: number;
      name: string;
    }>;
  }>;
}
export interface ErrorCodesResponse {
  data: Array<ErrorCodesType>;
  success: boolean;
  message: string;
}

export const getErrorCodesList = async (): Promise<ErrorCodesResponse> => {
  const { data } = await http.get('/error-codes');
  return data;
};
