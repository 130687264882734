import APIPATHES from 'constants/api-pathes';

/**
 * Saving data to localStorage.
 */
export const saveToLocalStorage = (name: string, values: unknown) => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return;
  }

  localStorage.setItem(`daikinc-${name}`, JSON.stringify(values));
};

/**
 * Load data from localStorage
 */
export const loadFromLocalStorage = (name: string) => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return null;
  }

  const serialized = window.localStorage.getItem(`daikinc-${name}`);

  return serialized !== null ? JSON.parse(serialized) : null;
};

export const removeFromLocalStorage = (name: string) => {
  if (typeof window === 'undefined' || !window.localStorage) return;

  window.localStorage.removeItem(`daikinc-${name}`);
};

/**
 * Download file
 */
export const downloadFile = (data: Blob, filemame: string) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const link = document.createElement('a');
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    link.href = downloadUrl;
    link.setAttribute('download', filemame);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

type Cookie = {
  name: string;
  value: string;
  exdays?: number;
};

/**
 * Handle cookie
 */

export const setCookie = ({ name, value, exdays }: Cookie) => {
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + (exdays || 0) * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}${exdays ? `; expires=${expiration.toUTCString()}` : ''}`;
};

export const getCookieValue = (name: string) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match && match[2];
};

/**
 * Handle absolute or relative url
 */
export const handleUrl = (url?: string) => {
  return url?.match(/^http/) ? url : `${APIPATHES['STORAGE']}${url}`;
};

export const getLanguageFromPath = (pathname: string): string => {
  const routes = pathname.split('/');
  if (routes.length > 0) {
    if (routes[routes.length - 1] === 'en') {
      return 'en';
    }
  }
  return pathname.includes('/en/') ? 'en' : 'vi';
};

export const checkExternalUrl = (str?: string) => {
  if (!str) return false;
  const tareaRegex = /^(http|https|tel)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const removePrefixCanonical = (link: string) => (link.substring(link.length - 1) === '/' ? link.substring(0, link.length - 1) : link);
