import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getDistributionCategoriesList, getDistributionList, getCities } from 'services/wheretobuy';
import {
  DISTRIBUTION_CATEGORIES,
  DISTRIBUTION_CATEGORIES_SUCCESS,
  DISTRIBUTION_LIST,
  DISTRIBUTION_LIST_ERROR,
  DISTRIBUTION_LIST_REQUEST,
  DISTRIBUTION_LIST_SUCCESS,
  GET_PROVINCE_LIST,
  GET_PROVINCE_LIST_SUCCESS,
} from 'store/types/where_to_buy';

function* distributionCategories() {
  const data = yield call(getDistributionCategoriesList);
  yield put({ type: DISTRIBUTION_CATEGORIES_SUCCESS, payload: { data } });
}

function* distributionList(action: {
  type: string;
  payload: {
    categoryId: number;
    keyword?: string;
    limit?: number;
    currentPage?: number;
    cityId?: number;
  };
}) {
  yield put({ type: DISTRIBUTION_LIST_REQUEST });
  try {
    const data = yield call(getDistributionList, {
      type: action.payload.categoryId,
      keyword: action.payload.keyword,
      limit: action.payload.limit,
      currentPage: action.payload.currentPage,
      cityId: action.payload.cityId,
    });

    yield put({
      type: DISTRIBUTION_LIST_SUCCESS,
      payload: { data: data, categoryId: action.payload.categoryId, page: action.payload.currentPage },
    });
  } catch (error) {
    yield put({ type: DISTRIBUTION_LIST_ERROR });
  }
}

function* cities() {
  const data = yield call(getCities);
  yield put({ type: GET_PROVINCE_LIST_SUCCESS, payload: { data } });
}

function* watchDistributionCategories() {
  yield takeEvery(DISTRIBUTION_CATEGORIES, distributionCategories);
}

function* watchDistributionList() {
  yield takeEvery(DISTRIBUTION_LIST, distributionList);
}

function* watchCities() {
  yield takeEvery(GET_PROVINCE_LIST, cities);
}

export default function* whereToBuySaga() {
  yield all([watchDistributionCategories(), watchDistributionList(), watchCities()]);
}
