import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getSystem } from 'services/system';
import { SYSTEM_CONFIG, SYSTEM_CONFIG_SUCCESS } from 'store/types/system';

function* systemConfig() {
  const systemConfigRes = yield call(getSystem);
  yield put({ type: SYSTEM_CONFIG_SUCCESS, payload: systemConfigRes });
}

function* watchSystemConfig() {
  yield takeEvery(SYSTEM_CONFIG, systemConfig);
}

export default function* systemConfigSaga() {
  yield all([watchSystemConfig()]);
}
