import { all, call, put, takeEvery } from 'redux-saga/effects';
import { HEADER_SUCCESS } from 'store/types/header';
import { FOOTER_LOADING, FOOTER_END_LOADING } from 'store/types/footer';
import { FOOTER_SUCCESS } from 'store/types/footer';
import { HEADER_END_LOADING } from 'store/types/header';

import { getAllMenu, MenuResponse, MenuItemType } from 'services/menu';
import { MENU } from 'store/types/menu';

function* menu() {
  // yield put({ type: HEADER_LOADING });
  yield put({ type: FOOTER_LOADING });
  const menus = (yield call(getAllMenu)) as MenuResponse;
  if (menus && menus.data) {
    const temp: { [key: string]: MenuItemType[] } = {};
    menus.data.forEach(item => {
      temp[item.code] = item.items;
    });
    yield put({
      type: HEADER_SUCCESS,
      payload: {
        main: { data: temp['main_menu'] },
        second: { data: temp['second_menu'] },
      },
    });
    yield put({
      type: FOOTER_SUCCESS,
      payload: {
        main: { data: temp['footer_menu_1'] },
        second: { data: temp['footer_menu_2'] },
      },
    });
  }
  // yield put({ type: HEADER_END_LOADING });
  yield put({ type: FOOTER_END_LOADING });
}

function* watchMenu() {
  yield takeEvery(MENU, menu);
  yield put({ type: HEADER_END_LOADING });
  yield put({ type: FOOTER_END_LOADING });
}

export default function* menuSaga() {
  yield all([watchMenu()]);
}
