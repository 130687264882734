import { updateLanguageFromUrl } from 'i18n';
import React, { useEffect } from 'react';
import { action, useAppSelector } from 'store';
import { REDIRECT_CLEAN } from 'store/types/redirect';

const Redirect301: React.FC = () => {
  const redirectStore = useAppSelector(state => state.redirect);

  useEffect(() => {
    const { redirectData } = redirectStore;
    if (redirectData) {
      updateLanguageFromUrl(redirectData.to);
      const finalPath = `${window.location.host}/${redirectData.to}`.replaceAll('//', '/');
      window.location.replace(`${window.location.protocol}//${finalPath}`);
    }

    action({ type: REDIRECT_CLEAN });
  }, [redirectStore]);

  return null;
};

export default Redirect301;
