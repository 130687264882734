import produce from 'immer';
import { FOOTER_SUCCESS, FOOTER_LOADING, FOOTER_END_LOADING, FooterStateType, ActionType } from 'store/types/footer';

const initialState: FooterStateType = {
  main: { data: [], message: '', success: false },
  second: { data: [], message: '', success: false },
  loaded: false,
  loading: true,
};

export const footerReducer = produce((draft: FooterStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case FOOTER_LOADING:
      draft.loading = true;
      return draft;
    case FOOTER_END_LOADING:
      draft.loading = false;
      return draft;
    case FOOTER_SUCCESS:
      draft.main = { ...action.payload.main, data: Object.values(action.payload.main.data) };
      draft.second = { ...action.payload.second, data: Object.values(action.payload.second.data) };
      draft.loaded = true;
      draft.loading = false;
      return draft;
    default:
      return draft;
  }
});
