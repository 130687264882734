import http from 'services/http';

export interface ContactTypeListType {
  success: boolean;
  message: string;
  data: Array<{
    id: number;
    name: string;
  }>;
}

export interface PostContactResponse {
  message: string;
  success: boolean;
}

export interface ContactPageType {
  title: string;
  pageMetaData: {
    bannerTitleDescriptionImage0: {
      heading: string;
      description: string;
      bgImage: string;
      tabletBgImage: string;
      mobileBgImage: string;
    };
    contactUsSectionMap0: {
      heading: string;
      content: string;
      lat: string;
      lng: string;
    };
    contactUsSectionForm0: {
      heading: string;
      fullNameLabel: string;
      fullNamePlaceholder: string;
      emailLabel: string;
      emailPlaceholder: string;
      phoneLabel: string;
      phonePlaceholder: string;
      companyLabel: string;
      companyPlaceholder: string;
      contactTypeLabel: string;
      contactTypePlaceholder: string;
      contentLabel: string;
      contentPlaceholder: string;
      headingButton: string;
    };
  };
}

export interface PostContactFormType {
  full_name: string;
  phone: string;
  email: string;
  contact_type_id: number;
  content: string;
  company_name: string;
}

export const getContactTypeList = async (): Promise<ContactTypeListType> => {
  const { data } = await http.get('/contact-types');
  return data;
};

export const postContactForm = async (dataForm: PostContactFormType): Promise<PostContactResponse> => {
  const { data } = await http.post('/contacts', dataForm);
  return data;
};
