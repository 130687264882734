import http from 'services/http';
import { ExtraMeta } from 'services/common';

export interface SystemServiceGoogleApiType {
  services: {
    google: {
      apiKey: string;
    };
  };
}

export interface TranslateType {
  vi: string;
  en: string;
}

export interface IntroModalContent {
  [key: string]: {
    active: number;
    image: string;
    link: {
      target: string;
      url: string;
    };
  };
}

export interface OpenGraphContent {
  [key: string]: ExtraMeta;
}

export interface SystemConfigItemContentType {
  id: number;
  key: string;
  content: string | TranslateType | IntroModalContent | SystemServiceGoogleApiType | string[] | OpenGraphContent;
}

interface SystemConfigType {
  canonical: SystemConfigItemContentType;
  logoWebsite: SystemConfigItemContentType;
  logoFooter: SystemConfigItemContentType;
  seoTitle: SystemConfigItemContentType;
  seoDescription: SystemConfigItemContentType;
  seoKeyword: SystemConfigItemContentType;
  receiverEmailHasContact: SystemConfigItemContentType;
  footerFacebookUrl: SystemConfigItemContentType;
  footerFacebookLogo: SystemConfigItemContentType;
  footerYoutubeUrl: SystemConfigItemContentType;
  footerYoutubeLogo: SystemConfigItemContentType;
  footerLinkedInUrl: SystemConfigItemContentType;
  footerLinkedInLogo: SystemConfigItemContentType;
  footerSocialText: SystemConfigItemContentType;
  config: SystemConfigItemContentType;
  scriptGoogleAnalytics: SystemConfigItemContentType;
  gaTrackingIds: SystemConfigItemContentType;
  gtmIds: SystemConfigItemContentType;
  popupWebsite: SystemConfigItemContentType;
  openGraph: SystemConfigItemContentType;
  pixel: SystemConfigItemContentType;
}

export interface SystemConfigResponse {
  success: boolean;
  message: string;
  data: SystemConfigType;
}

export const getSystem = async (): Promise<SystemConfigResponse> => {
  const { data } = await http.get('/system-config');
  return data;
};
