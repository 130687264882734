import produce from 'immer';
import { ActionType, TechnologyBenefitMenuState, TECHNOLOGY_MENU_SUCCESS } from 'store/types/discovery/technology';

const initialState: TechnologyBenefitMenuState = {
  success: false,
  message: '',
  data: {},
  loaded: false,
};

export const technologyReducer = produce((draft: TechnologyBenefitMenuState = initialState, action: ActionType) => {
  if (action.type === TECHNOLOGY_MENU_SUCCESS) {
    draft = { ...action.payload, loaded: true };
    return draft;
  }

  return draft;
});
