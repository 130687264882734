import React from 'react';
import { mapModifiers } from 'lib/component';
import { Link } from 'components/utils/link';
import { IconName, Icon } from 'components/atoms/icon';

type ButtonType = 'outline' | 'round';
type Modifiers = 'medium' | 'large' | 'success' | 'black' | 'white' | 'inline';
interface Props {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  type?: 'submit' | 'button';
  href?: string;
  target?: string;
  buttonType?: ButtonType | ButtonType[];
  modifiers?: Modifiers | Modifiers[];
  iconName?: IconName;
  disabled?: boolean;
  backgroundImage?: string;
}

export const Button: React.FC<Props> = props =>
  props.href ? (
    <Link
      style={{ backgroundImage: props.backgroundImage && `url(${props.backgroundImage})` }}
      className={mapModifiers(
        'a-button',
        props.modifiers,
        props.buttonType,
        props.disabled && 'disabled',
        props.backgroundImage && 'hasbackgroundimage'
      )}
      to={props.href}
      target={props.target}
    >
      {props.iconName && (
        <div className="a-button_iconwrapper">
          <Icon iconName={props.iconName} />
        </div>
      )}

      <span>{props.children}</span>
    </Link>
  ) : (
    <button
      style={{ backgroundImage: props.backgroundImage && `url(${props.backgroundImage})` }}
      type={props.type}
      className={mapModifiers(
        'a-button',
        props.modifiers,
        props.buttonType,
        props.disabled && 'disabled',
        props.backgroundImage && 'hasbackgroundimage'
      )}
      onClick={props.onClick}
    >
      {props.iconName && (
        <div className="a-button_iconwrapper">
          <Icon iconName={props.iconName} />
        </div>
      )}

      <span>{props.children}</span>
    </button>
  );

Button.defaultProps = { type: 'button' };
