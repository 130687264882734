import http from 'services/http';
import { BreadcrumbsType, MetaDataType } from 'services/common';

export interface CaseStudyCategoriesResponse {
  success: boolean;
  message: string;
  data: Array<{
    id: number;
    name: string;
    slug: string;
    icon: string;
    iconHover: string;
  }>;
  loaded: boolean;
}

export interface CaseStudyResponse {
  success: boolean;
  message: string;
  meta: {
    totalPages: number;
    limit: number;
    total: number;
    page: number;
  };
  data: Array<CaseStudyType>;
  loaded: boolean;
}

export interface CaseStudyDetailResponse {
  success: boolean;
  message: string;
  data: {
    data: {
      id: number;
      title: string;
      description: string;
      categories: Array<{ id: number; name: string }>;
      section1Title: string;
      section1Content: string;
      section1Images: Array<string>;
      section2Title: string;
      section2Content: string;
      section2Image: string;
      section3Image: string;
      section3LinkText: string;
      section3LinkUrl: string;
      section3Introduction: Array<{ icon: string; title: string; subTitle: string }>;
      translations: Array<{ locale: string; slug: string | null }>;
    };
    metaData: MetaDataType;
    breadcrumbs: BreadcrumbsType;
  };
}

export interface CaseStudyType {
  id: number;
  title: string;
  description: string;
  categories: Array<{ name: string }>;
  slug: string;
  thumbnail: Array<string>;
  city: {
    name: string;
  };
}

export interface CaseStudyPageType {
  slug: string;
  title: string;
  name: string;
  pageMetaData: {
    bannerTagTitleDescriptionImage0: {
      bgImage: string;
      description: string;
      heading: string;
      mobileBgImage: string;
      tabletBgImage: string;
      tag: string;
    };
  };
}

export const getCaseStudyCategoryList = async (): Promise<CaseStudyCategoriesResponse> => {
  const { data } = await http.get('/case-study-categories');
  return data;
};

export const getCaseStudyListByCategorySlug = async (
  category_ids: number,
  page: number
): Promise<CaseStudyResponse> => {
  const { data } = await http.get('/case-studies', {
    params: { limit: 6, page, category_ids },
  });
  return data;
};

export const getCaseStudySlugDetail = async (slugDetail: string): Promise<CaseStudyDetailResponse> => {
  const { data } = await http.get(`/case-studies/slug/${slugDetail}`);
  return data;
};
