import http from 'services/http';

export interface TechnologyPageType {
  title: string;
  pageMetaData: {
    technologySectionLogoTitleDescription0: {
      logo: string;
      heading: string;
      description: string;
    };
    technologySectionDescription0: {
      description: string;
    };
  };
  metaData: {
    title: string;
    description: string;
    keywords: string;
  };
}

export interface TechnologyBenefitResponse {
  success: boolean;
  message: string;
  data: {
    [x: string]: {
      id: number;
      title: string;
      reference: {
        slug: string;
      } | null;
      link: string;
      target: string;
    };
  };
}

export const getTechnologyBenefitMenu = async (): Promise<TechnologyBenefitResponse> => {
  const { data } = await http.get('/get-menu/left_sidebar_technology_benefit');
  return data;
};
