import { SystemConfigResponse } from 'services/system';

export const SYSTEM_CONFIG = 'SYSTEM_CONFIG';
export const SYSTEM_CONFIG_SUCCESS = 'SYSTEM_CONFIG_SUCCESS';

export interface SystemConfigStateType {
  system?: SystemConfigResponse;
  loaded: boolean;
}

export type ActionType = {
  type: string;
  payload: SystemConfigResponse;
};
