import React, { useEffect } from 'react';

interface Props {
  helmet?: React.ReactNode;
}

export const Notfound: React.FC<Props> = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-notfound">
      {props.helmet}
      {props.children}
    </div>
  );
};
