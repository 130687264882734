import { GuidesResponse } from 'services/discovery/guides';
import { ErrorCodesResponse } from 'services/services/error_codes';

export const ERROR_CODES = 'ERROR_CODES';
export const ERROR_CODES_SUCCESS = 'ERROR_CODES_SUCCESS';

export interface ErrorCodesStateType {
  relativeGuides: GuidesResponse;
  errorCodes: ErrorCodesResponse;
  loaded: boolean;
}

export type ActionType = {
  type: typeof ERROR_CODES_SUCCESS;
  payload: { relativeGuides: GuidesResponse; errorCodes: ErrorCodesResponse };
};
