import React from 'react';
import { Text } from 'components/atoms/text';
import { mapModifiers } from 'lib/component';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  withoutLabel?: boolean;
}

export const Checkbox: React.FC<Props> = ({ title, withoutLabel, ...innerProps }) => {
  return (
    <div className={mapModifiers('a-checkbox', withoutLabel && 'withoutlabel')}>
      {withoutLabel ? (
        <>
          <input {...innerProps} className="a-checkbox_input" type="checkbox" />
          <span className="a-checkbox_checkmark"></span>
          <Text>{title}</Text>
        </>
      ) : (
        <label className="a-checkbox_label">
          <input {...innerProps} className="a-checkbox_input" type="checkbox" />
          <span className="a-checkbox_checkmark"></span>
          <Text>{title}</Text>
        </label>
      )}
    </div>
  );
};
