import { TechnologyBenefitResponse } from 'services/discovery/technology';

export const TECHNOLOGY_MENU = 'TECHNOLOGY_MENU';
export const TECHNOLOGY_MENU_SUCCESS = 'TECHNOLOGY_MENU_SUCCESS';

export interface TechnologyBenefitMenuState extends TechnologyBenefitResponse {
  loaded: boolean;
}

export type ActionType = {
  type: typeof TECHNOLOGY_MENU_SUCCESS;
  payload: TechnologyBenefitResponse;
};
