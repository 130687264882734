import { all, call, put, takeEvery } from 'redux-saga/effects';
import { GET_TRAINING_MENU, GET_TRAINING_MENU_SUCCESS } from 'store/types/partner/training';
import { getTrainingServices } from 'services/partner/training';

function* trainingMenu() {
  const res = yield call(getTrainingServices);
  yield put({ type: GET_TRAINING_MENU_SUCCESS, payload: res });
}

function* watchTrainingMenu() {
  yield takeEvery(GET_TRAINING_MENU, trainingMenu);
}

export default function* trainingSaga() {
  yield all([watchTrainingMenu()]);
}
