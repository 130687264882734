import { TrainingMenuResponse } from 'services/partner/training';

export const GET_TRAINING_MENU = 'GET_TRAINING';
export const GET_TRAINING_MENU_SUCCESS = 'GET_TRAINING_MENU_SUCCESS';

export interface TrainingMenuState extends TrainingMenuResponse {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_TRAINING_MENU_SUCCESS;
  payload: TrainingMenuResponse;
};
