import { DistributionCategoriesType, WhereToBuyType, CitiesType, WhereToBuyDataType } from 'services/wheretobuy';

export const DISTRIBUTION_CATEGORIES = 'DISTRIBUTION_CATEGORIES';
export const DISTRIBUTION_CATEGORIES_SUCCESS = 'DISTRIBUTION_CATEGORIES_SUCCESS';
export const DISTRIBUTION_LIST = 'DISTRIBUTION_LIST';
export const DISTRIBUTION_LIST_SUCCESS = 'DISTRIBUTION_LIST_SUCCESS';
export const DISTRIBUTION_LIST_REQUEST = 'DISTRIBUTION_LIST_REQUEST';
export const DISTRIBUTION_LIST_ERROR = 'DISTRIBUTION_LIST_ERROR';
export const GET_PROVINCE_LIST = 'GET_PROVINCE_LIST';
export const GET_PROVINCE_LIST_SUCCESS = 'GET_PROVINCE_LIST_SUCCESS';

export interface WhereToBuyStateType {
  distributionCategoryList: DistributionCategoriesType;
  distributionList: {
    [categoryId: number]: WhereToBuyType;
    loaded: boolean;
    loading?: boolean;
  };
  distributionViewList: WhereToBuyDataType[];
  cities: CitiesType;
}

export type ActionType = {
  type:
    | typeof DISTRIBUTION_CATEGORIES_SUCCESS
    | typeof DISTRIBUTION_LIST_SUCCESS
    | typeof DISTRIBUTION_LIST_REQUEST
    | typeof DISTRIBUTION_LIST_ERROR
    | typeof GET_PROVINCE_LIST_SUCCESS;
  payload: {
    categoryId?: number;
    data: DistributionCategoriesType | WhereToBuyType;
    page?: number;
  };
};
