import produce from 'immer';
import { SeriesIdResponse } from 'services/product';
import {
  GET_SERIES_ID_SUCCESS,
  PUT_PRODUCT_SELECTED_SUCCESS,
  ComparisonStateType,
  ProductSelectedType,
  ActionType,
} from 'store/types/comparison';

const initialSate: ComparisonStateType = {};

export const comparisonReducer = produce((draft: ComparisonStateType = initialSate, action: ActionType) => {
  switch (action.type) {
    case GET_SERIES_ID_SUCCESS: {
      draft.currentSeries = action.payload as SeriesIdResponse;
      return draft;
    }
    case PUT_PRODUCT_SELECTED_SUCCESS: {
      draft.productsCompare = action.payload as ProductSelectedType;
      return draft;
    }
    default:
      return draft;
  }
});
