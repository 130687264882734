import produce from 'immer';
import { GET_TRAINING_MENU_SUCCESS, ActionType, TrainingMenuState } from 'store/types/partner/training';

const initialState: TrainingMenuState = {
  message: '',
  success: false,
  data: {},
  loaded: false,
};

export const trainingReducer = produce((draft: TrainingMenuState = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_TRAINING_MENU_SUCCESS:
      draft = {
        ...action.payload,
        loaded: true,
      };
      return draft;
    default:
      return draft;
  }
});
