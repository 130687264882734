import { ContactTypeListType } from 'services/contact';

export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';

export interface ContactStateType extends ContactTypeListType {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_CONTACT_SUCCESS;
  payload: ContactTypeListType;
};
