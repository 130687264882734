import http from 'services/http';

export interface PageRedirectResponse {
  id: number;
  from: string;
  to: string;
}

export const getPageRedirect = async (slug: string): Promise<PageRedirectResponse> => {
  const { data } = await http.get(`/systems/redirects/show?path=${slug}`);
  return data.data;
};
