import { PageRedirectResponse } from 'services/redirect';

export const REDIRECT = 'REDIRECT';
export const REDIRECT_SUCCESS = 'REDIRECT_SUCCESS';
export const REDIRECT_CLEAN = 'REDIRECT_CLEAN';
export const REDIRECT_ERROR = 'REDIRECT_ERROR';

export interface RedirectStateType {
  redirectData?: PageRedirectResponse;
  isError: boolean;
}

export type ActionType = {
  type: typeof REDIRECT_SUCCESS | typeof REDIRECT_CLEAN | typeof REDIRECT_ERROR;
  payload?: PageRedirectResponse;
};
