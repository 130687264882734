import produce from 'immer';
import {
  GET_CATALOGUE_SUCCESS,
  GET_CATALOGUE_MORE_SUCCESS,
  CatalogueStateType,
  ActionType,
  GET_CATALOGUE_BY_IDS_SUCCESS,
} from 'store/types/services/catalogue';

const initialState: CatalogueStateType = {
  catalogueList: {
    data: [],
    message: '',
    success: false,
    meta: {
      limit: 0,
      page: 0,
      total: 0,
      totalPages: 0,
    },
  },
  loaded: false,
};

export const catalogueReducer = produce((draft: CatalogueStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_CATALOGUE_SUCCESS: {
      draft = { ...action.payload, loaded: true };
      return draft;
    }
    case GET_CATALOGUE_MORE_SUCCESS: {
      draft.catalogueList = {
        ...action.payload.catalogueList,
        data: [...draft.catalogueList.data, ...action.payload.catalogueList.data],
      };
      return draft;
    }
    case GET_CATALOGUE_BY_IDS_SUCCESS: {
      draft.catalogueList = action.payload.catalogueList;
      draft.optionsSelected = action.payload.optionsSelected;
      draft.loaded = true;
      return draft;
    }
    default:
      return draft;
  }
});
