import { FooterResponse } from 'services/footer';

export const FOOTER = 'FOOTER';
export const FOOTER_LOADING = 'FOOTER_LOADING';
export const FOOTER_END_LOADING = 'FOOTER_END_LOADING';
export const FOOTER_SUCCESS = 'FOOTER_SUCCESS';

export interface FooterCombineType {
  main: FooterResponse;
  second: FooterResponse;
}

export interface FooterStateType extends FooterCombineType {
  loaded: boolean;
  loading?: boolean;
}

export type ActionType = {
  type: string;
  payload: FooterCombineType;
};
