import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, action } from 'store';
import { MenuItemType } from 'services/header';
import { getPath } from 'lib/pathes';
import APIPATHES from 'constants/api-pathes';
import { mapModifiers } from 'lib/component';
import { changeLanguage } from 'lib/history';
import { getMenuLink } from 'lib/menu';
import { Container } from 'components/organisms/grid';
import { Image } from 'components/atoms/image';
import { Language, LanguageItem } from 'components/atoms/language';
import { Link } from 'components/utils/link';
import { Textlink } from 'components/atoms/textlink';
import { FOOTER_LOADING } from 'store/types/footer';
import { HEADER_LOADING } from 'store/types/header';
import LazyLoad from 'react-lazyload';

export const Footer: React.FC = () => {
  const { i18n } = useTranslation();
  const {
    footer: { main, second, loading },
    system: { system },
  } = useAppSelector(state => ({
    footer: state.footer,
    system: state.system,
  }));

  const getSubMenu = useCallback((data: MenuItemType, isLoading?: boolean) => {
    if (data.children.length === 0) return undefined;

    return (
      <ul>
        {data.children.map(item => (
          <li className={mapModifiers('o-footer_item', item.cssClass)} key={item.id}>
            <Textlink
              useNative={item.link?.includes('tel+')}
              href={getMenuLink(item, i18n.language, data)}
              target={item.target}
              disabled={isLoading}
            >
              <div className="o-footer_dotwrapper">
                <div className={item.cssClass && item.cssClass.includes('new_red') ? 'o-footer_dot' : ''} />
                {item.title}
              </div>
            </Textlink>
          </li>
        ))}
      </ul>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeLangue = useCallback(
    (lng: string) => {
      if (lng !== i18n.language) {
        changeLanguage(lng);
      }
      action({ type: HEADER_LOADING });
      action({ type: FOOTER_LOADING });
    },
    [i18n.language]
  );

  return (
    <footer className="o-footer">
      <LazyLoad height={300} once>
        <div className="o-footer_top">
          <Container>
            {system && (
              <figure className="o-footer_logo">
                <Link to={getPath('APP')}>
                  <Image
                    lazy={false}
                    src={`${APIPATHES.STORAGE}${system.data.logoFooter.content}`}
                    alt={system.data.logoFooter.key}
                  />
                </Link>
              </figure>
            )}
            <div className="o-footer_list">
              {main.data.map(item => (
                <FooterInfo
                  title={item.title}
                  content={getSubMenu(item, loading)}
                  disabled={loading}
                  key={item.id.toString()}
                  href={getMenuLink(item, i18n.language)}
                  modifiers={item.cssClass === 'highlight' ? 'nocollapse' : undefined}
                  target={item.target}
                  cssClass={item.cssClass}
                />
              ))}
            </div>
          </Container>
        </div>
        <div className="o-footer_bottom">
          <Container>
            <div className="o-footer_bottomwrap">
              <div className="o-footer_wraplang">
                <ul>
                  <li>
                    <Language langActived={i18n.language}>
                      <LanguageItem
                        title="Tiếng Việt"
                        value="vi"
                        active={i18n.language === 'vi'}
                        onClick={() => onChangeLangue('vi')}
                      />
                      <LanguageItem
                        title="English"
                        value="en"
                        active={i18n.language === 'en'}
                        onClick={() => onChangeLangue('en')}
                      />
                    </Language>
                  </li>
                  {second.data.map(item => (
                    <li key={item.id}>
                      <Textlink href={getMenuLink(item, i18n.language)} target={item.target} disabled={loading}>
                        {item.title}
                      </Textlink>
                    </li>
                  ))}
                  {/* <li>
                  <Textlink href={getPath('SITEMAP')} disabled={loading}>
                    {t('sitemap.title')}
                  </Textlink>
                </li> */}
                </ul>
              </div>
              {system && (
                <div className="o-footer_wrapsocial">
                  <div>
                    {
                      (system.data.footerSocialText.content as { vi: string; en: string })[
                        i18n.language as keyof { vi: string; en: string }
                      ]
                    }
                  </div>
                  <ul>
                    <li>
                      <Textlink useNative href={system.data?.footerFacebookUrl?.content as string}>
                        <Image
                          src={`${APIPATHES.STORAGE}${system.data?.footerFacebookLogo?.content as string}`}
                          alt={system.data?.footerFacebookLogo?.key || ''}
                        />
                      </Textlink>
                    </li>
                    <li>
                      <Textlink useNative href={system.data?.footerLinkedInUrl?.content as string}>
                        <Image
                          src={`${APIPATHES.STORAGE}${system.data?.footerLinkedInLogo?.content as string}`}
                          alt={system.data?.footerLinkedInLogo?.key || ''}
                        />
                      </Textlink>
                    </li>
                    <li>
                      <Textlink useNative href={system.data?.footerYoutubeUrl?.content as string}>
                        <Image
                          src={`${APIPATHES.STORAGE}${system.data?.footerYoutubeLogo?.content as string}`}
                          alt={system.data?.footerYoutubeLogo?.key || ''}
                        />
                      </Textlink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </Container>
        </div>
      </LazyLoad>
    </footer>
  );
};

interface FooterInfoProps {
  key?: string;
  title?: string;
  href?: string;
  content?: React.ReactNode;
  modifiers?: 'nocollapse';
  target?: string;
  disabled?: boolean;
  cssClass?: string;
}

export const FooterInfo: React.FC<FooterInfoProps> = props => {
  const [isActive, setActive] = useState(false);

  return (
    <div
      className={mapModifiers('o-footer_info', props.modifiers, !!props.content && 'withcontent', isActive && 'active')}
    >
      {props.title && props.href && (
        <h4
          className={mapModifiers('o-footer_infotitle', props.disabled && 'disabled')}
          onClick={() => props.content && !props.modifiers?.includes('nocollapse') && setActive(!isActive)}
        >
          {props.disabled ? (
            <span className="o-footer_info-disabled">{props.title}</span>
          ) : (
            <Link
              useNative={props.href?.includes('tel')}
              to={props.href}
              target={props.target}
              className="o-footer_dotwrapper"
            >
              <div className={props.cssClass && props.cssClass.includes('new_red') ? 'o-footer_dot' : ''} />
              {props.title}
            </Link>
          )}
        </h4>
      )}
      {props.content && <div className="o-footer_infocontent">{props.content}</div>}
    </div>
  );
};
