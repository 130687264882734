import { GroupType } from 'react-select';
import { OptionType } from 'components/molecules/downloadselect';
import { CatalogueResponse } from 'services/services/catalogue';

export const GET_CATALOGUE = 'GET_CATALOGUE';
export const GET_CATALOGUE_SUCCESS = 'GET_CATALOGUE_SUCCESS';
export const GET_CATALOGUE_MORE = 'GET_CATALOGUE_MORE';
export const GET_CATALOGUE_MORE_SUCCESS = 'GET_CATALOGUE_MORE_SUCCESS';
export const GET_CATALOGUE_BY_IDS = 'GET_CATALOGUE_BY_IDS';
export const GET_CATALOGUE_BY_IDS_SUCCESS = 'GET_CATALOGUE_BY_IDS_SUCCESS';

interface CataloguePayloadType {
  catalogueList: CatalogueResponse;
  optionsSelected?: Array<GroupType<OptionType>>;
}
export interface CatalogueStateType extends CataloguePayloadType {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_CATALOGUE_MORE_SUCCESS | typeof GET_CATALOGUE_SUCCESS | typeof GET_CATALOGUE_BY_IDS_SUCCESS;
  payload: CataloguePayloadType;
};
