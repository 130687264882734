import { CaseStudyCategoriesResponse, CaseStudyResponse } from 'services/discovery/casestudy';

export const CASESTUDY_CATEGORIES = 'CASESTUDY_CATEGORIES';
export const CASESTUDY_CATEGORIES_SUCCESS = 'CASESTUDY_CATEGORIES_SUCCESS';
export const CASESTUDIES = 'CASESTUDIES';
export const CASESTUDIES_SUCCESS = 'CASESTUDIES_SUCCESS';

export interface CaseStudyStateType {
  categories: CaseStudyCategoriesResponse;
  casestudies: {
    [key: number]: CaseStudyResponse;
    loaded: boolean;
  };
}

export type ActionType = {
  type: typeof CASESTUDY_CATEGORIES_SUCCESS | typeof CASESTUDIES_SUCCESS;
  payload: { data: CaseStudyResponse | CaseStudyCategoriesResponse; category?: number };
};
