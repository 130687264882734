import produce from 'immer';
import {
  GET_MENU_DAIKIN_VIETNAM_SUCCESS,
  ActionType,
  DaikinVietnamMenuState,
} from 'store/types/about_us/daikin_vietnam';

const initialSate: DaikinVietnamMenuState = {
  success: false,
  message: '',
  data: {},
  loaded: false,
};

export const daikinVietNamReducer = produce((draft: DaikinVietnamMenuState = initialSate, action: ActionType) => {
  if (action.type === GET_MENU_DAIKIN_VIETNAM_SUCCESS) {
    draft = {
      ...action.payload,
      loaded: true,
    };
  }
  return draft;
});
