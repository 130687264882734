import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createStore, applyMiddleware, compose, Store, Action } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, PersistedState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import rootSaga from './sagas';

const PersistVersion = 2;

// Create a Persist-Config
const persistConfig = {
  key: 'daikinC',
  storage,
  version: PersistVersion,
  whitelist: ['comparison', 'header', 'footer'],
  blacklist: ['system'],
  migrate: (state: PersistedState) => {
    // eslint-disable-next-line no-underscore-dangle
    if (PersistVersion !== state?._persist.version) return Promise.resolve((null as unknown) as PersistedState);
    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Get the compose enhancers through the `window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__` or the redux plugin's `compose`
const composeEnhancers =
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// Create a new SagaMiddleware instance
const sagaMiddleware = createSagaMiddleware();

// Create initial state
const initialState = {};

// Make a list of middleware being used in the project
const middleware = [sagaMiddleware];

const enhancer = composeEnhancers(applyMiddleware(...middleware));

// Make a type of the Root-Reducer
type RootState = ReturnType<typeof rootReducer>;

// Create a store
const store: Store<RootState, Action> = createStore(persistedReducer, initialState, enhancer);

// Run the Saga-Middleware
sagaMiddleware.run(rootSaga);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const action = ({ type, payload }: { type: any; payload?: any }) => store.dispatch({ type, payload });

export const persistor = persistStore(store);

export default store;
