import React from 'react';
import { mapModifiers } from 'lib/component';

type Modifiers = 'nonmargin' | 'fullwidth';

interface Props {
  primary: React.ReactNode;
  modifiers?: Modifiers | Modifiers[];
}

export const Buttoncontainer: React.FC<Props> = props => {
  return <div className={mapModifiers('m-buttoncontainer', props.modifiers)}>{props.primary}</div>;
};
