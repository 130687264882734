import React, { useRef, useEffect } from 'react';
import { mapModifiers } from 'lib/component';
import { Container } from 'components/organisms/grid';

export type Modifier =
  | 'bghalf'
  | 'bgwhite'
  | 'bgpalegrey'
  | 'bgcerulean'
  | 'bgwhitefour'
  | 'bgwhitefive'
  | 'bgwhitesix'
  | 'nomargin'
  | 'nopadding'
  | 'nopaddingtop'
  | 'nopaddingbottomsp'
  | 'cardlinkoverlap'
  | 'containgallery'
  | 'bghalfrtl'
  | 'onaboutdaikin';

interface Props {
  modifiers?: Modifier | Modifier[];
  useDiv?: boolean;
  imgSrc?: string;
  imgTabSrc?: string;
  imgSpSrc?: string;
  withParallaxBg?: boolean;
  id?: string;
  isHiddenOnSp?: boolean;
}

export const Section: React.FC<Props> = props => {
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const sectionTarget = sectionRef.current;

    if (!sectionTarget) return undefined;

    if (!props.imgTabSrc && !props.imgSpSrc) return undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDettectResize = (mqle: any) => {
      if (mqle.matches) {
        if (props.imgTabSrc) {
          sectionTarget.style.backgroundImage = `url(${props.imgTabSrc})`;
          sectionTarget.style.backgroundPosition = 'left';
        }
      } else {
        sectionTarget.style.backgroundImage = `url(${
          window.innerWidth <= 769 && props.imgSpSrc ? props.imgSpSrc : props.imgSrc
        })`;
        sectionTarget.style.backgroundPosition = window.innerWidth <= 769 && props.imgSpSrc ? 'top' : 'center';
      }
    };

    // NOTE: About 769px, this is IpadMini's size (temporary size in this component when matchMedia)
    const mql = window.matchMedia('screen and (max-width: 1199px) and (min-width: 769px)');
    mql.addEventListener('change', handleDettectResize);
    handleDettectResize(mql);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.useDiv) {
    return (
      <div
        id={props.id}
        className={mapModifiers(
          'o-section',
          props.modifiers,
          props.imgSrc && 'withbgimage',
          props.withParallaxBg && 'withparallaxbg',
          props.isHiddenOnSp && 'hiddenonsp'
        )}
        style={props.imgSrc ? { backgroundImage: `url(${props.imgSrc})` } : {}}
        ref={sectionRef}
      >
        <Container className="o-section_container">{props.children}</Container>
      </div>
    );
  }

  return (
    <section
      id={props.id}
      className={mapModifiers(
        'o-section',
        props.modifiers,
        props.imgSrc && 'withbgimage',
        props.withParallaxBg && 'withparallaxbg',
        props.isHiddenOnSp && 'hiddenonsp'
      )}
      style={props.imgSrc ? { backgroundImage: `url(${props.imgSrc})` } : {}}
      ref={sectionRef}
    >
      <Container className="o-section_container">{props.children}</Container>
    </section>
  );
};
