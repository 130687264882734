import React from 'react';
import {
  Container as ContainerBootstrap,
  Row as RowBootstrap,
  Col as ColBootstrap,
  ContainerProps,
  RowProps,
  ColProps,
} from 'react-bootstrap';

export const Container: React.FC<ContainerProps> = props => <ContainerBootstrap {...props} />;
export const Row: React.FC<RowProps> = props => <RowBootstrap {...props} />;
export const Col: React.FC<ColProps> = props => <ColBootstrap {...props} />;
