import { mapModifiers } from 'lib/component';
import React from 'react';

interface Props {
  nonFixed?: boolean;
  variant?: 'default' | 'spin';
}

export const Loading: React.FC<Props> = props => {
  return (
    <div className={mapModifiers('a-loading', props.nonFixed && 'nonfixed')}>
      {props.variant === 'spin' && (
        <div className="a-loading_flexWrapper">
          <div className="a-loading_spin" />
        </div>
      )}
      {(!props.variant || props.variant === 'default') && <div className="a-loading_loader" />}
    </div>
  );
};
