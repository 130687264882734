import http from 'services/http';

export interface DaikinVietNamPageType {
  id: number;
  name: string;
  templateId: number;
  isHomepage: number;
  templateCode: string;
  title: string;
  description: string;
  content: string;
  slug: string;
  pageMetaData: {
    daikinVietnamSectionDaikinVietnam0: {
      heading: string;
      description: string;
      imageMap: string;
      items: {
        [key: number]: {
          name: string;
          number: string;
        };
      };
    };
    daikinVietnamSectionCSRActivities0: {
      heading: string;
      description: string;
      link: {
        text: string;
        link: string;
      };
      items: {
        [key: number]: {
          imageItem: string;
        };
      };
    };
    daikinVietnamSectionHistory0: {
      heading: string;
      description: string;
      link: {
        text: string;
        link: string;
      };
      items: {
        [key: number]: {
          imageItem: string;
        };
      };
    };
    daikinVietnamSectionFactory0: {
      heading: string;
      description: string;
      link: {
        text: string;
        link: string;
      };
      items: {
        [key: number]: {
          imageItem: string;
        };
      };
    };
    daikinVietnamSectionAwardsCertificates0: {
      heading: string;
      content: string;
      items: {
        [key: number]: {
          headingItem: string;
          yearItem: string;
          image: string;
          contentItem: string;
        };
      };
    };
    daikinVietnamSectionVietnamNetwork0: {
      heading: string;
      items: {
        [key: number]: {
          address: string;
          contact: {
            link: string;
            text: string;
          };
          email: string;
          fax: string;
          lat: string;
          long: string;
          map: string;
          name: string;
          phone: string;
          thumbnail: string;
        };
      };
    };
    daikinVietnamSectionVietnamServiceCenters0: {
      heading: string;
      items: {
        [key: number]: {
          address: string;
          lat: string;
          long: string;
          name: string;
          phone: string;
        };
      };
    };
  };
}

export interface DaikinVietnamMenuResponse {
  success: boolean;
  message: string;
  data: {
    [key: number]: {
      id: number;
      image: string;
      imageHover: string;
      reference: {
        slug: string;
      } | null;
      link: string;
      title: string;
      type: string;
      target: string;
    };
  };
  loaded: boolean;
}

export const getMenuDaikinVietnam = async (): Promise<DaikinVietnamMenuResponse> => {
  const { data } = await http.get('get-menu/about_us_menu');
  return data;
};
