import React, { useState, useContext, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { mapModifiers } from 'lib/component';
import { onToggleMenuContent } from 'lib/menu';
import { Link } from 'components/utils/link';
import { Image } from 'components/atoms/image';
import { HeaderContext } from 'components/organisms/header';
import apiPathes from 'constants/api-pathes';
import { checkExternalUrl } from 'lib/utils';

export const Menu: React.FC = props => {
  return <div className="m-menu">{props.children}</div>;
};

type MenuItemModifier = 'bold' | 'nonarrow' | 'uppercase' | 'button';

interface MenuItemProps {
  modifiers?: MenuItemModifier | MenuItemModifier[];
  title: string;
  to: string;
  active?: boolean;
  menuCard?: React.ReactNode;
  target?: string;
  isBottomMenu?: boolean;
  loading?: boolean;
  cssClass?: string;
  icon?: string;
  isContactUs?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = props => {
  const menuItemRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const [isCollapsed, setCollapsed] = useState<boolean>(false);
  const HeaderContextValue = useContext(HeaderContext);

  const handleCollapsed = useCallback(() => window.innerWidth <= 991 && setCollapsed(!isCollapsed), [isCollapsed]);
  const handleClickTitle = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (!props.loading) {
        e.stopPropagation();
        if (checkExternalUrl(props.to)) {
          window.open(props.to, props.target || '_self');
        } else {
          history.push(props.to);
        }
        HeaderContextValue?.closeMenuBar();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [HeaderContextValue?.closeMenuBar, history, props.loading]
  );

  return props.children ? (
    <div
      className={`${mapModifiers(
        'm-menu_menuitem',
        'haschildren',
        props.active && 'active',
        props.modifiers,
        !!props.menuCard && 'hasmenucard',
        isCollapsed && 'collapsed',
        props.isBottomMenu && 'bottommenu',
        props.isContactUs && 'button-contactus'
      )}${props.cssClass ? ` ${props.cssClass}` : ''}`}
      ref={menuItemRef}
    >
      <span className={mapModifiers('m-menu_title', props.loading && 'disabled')} onClick={handleCollapsed}>
        {props.cssClass && props.cssClass.includes('new_red') && <div className="m-menu_dot" />}
        <span onClick={handleClickTitle}>{props.title}</span>
      </span>
      {!props.loading && (
        <div className="m-menu_content">
          <div className="m-menu_wrapsubmenu">
            <span className="m-menu_categorysubmenu">{props.title}</span>
            {props.children}
          </div>
          {props.menuCard && <ul className="m-menu_wrapcardmenu">{props.menuCard}</ul>}
        </div>
      )}
    </div>
  ) : props.loading ? (
    <div className={mapModifiers('m-menu_menuitem', props.active && 'active', props.modifiers)}>
      <span className="m-menu_title m-menu_title-disabled">{props.title}</span>
    </div>
  ) : (
    <Link
      to={props.to}
      target={props.target}
      className={mapModifiers('m-menu_menuitem', props.active && 'active', props.modifiers, props.isContactUs && 'button-contactus')}
      onClick={HeaderContextValue?.closeMenuBar}
    >
      <span className="m-menu_title">
        {
          props.icon && (
            <Image src={`${apiPathes.STORAGE}${props.icon}`} alt={props.title} modifiers={['transparent']} />
          )
        }
        {props.cssClass && props.cssClass.includes('new_red') && <div className="m-menu_dot" />}
        {props.title}
      </span>
    </Link>
  );
};

interface SubMenuProps {
  children: React.ReactNode;
}

export const SubMenu: React.FC<SubMenuProps> = props => {
  return <ul className="m-menu_submenu">{props.children}</ul>;
};

interface SubMenuItemProps {
  title: string;
  to: string;
  target?: string;
  active?: boolean;
  cssClass?: string;
}

export const SubMenuItem: React.FC<SubMenuItemProps> = props => {
  const HeaderContextValue = useContext(HeaderContext);

  const onClickSubMenuItem = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
      HeaderContextValue?.closeMenuBar();
      if (window.innerWidth > 991) {
        const contentTarget = e.currentTarget.closest('.m-menu_content') as HTMLDivElement;
        onToggleMenuContent(contentTarget);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [HeaderContextValue?.closeMenuBar]
  );

  return (
    <li
      className={`${mapModifiers(
        'm-menu_submenuitem',
        props.active && 'active',
        props.children ? 'haschildren' : undefined
      )}${props.cssClass ? ` ${props.cssClass}` : ''}`}
    >
      <Link to={props.to} target={props.target} onClick={onClickSubMenuItem}>
        <span className="m-menu_submenuitem_wrapper">
          {props.cssClass && props.cssClass.includes('new_red') && <div className="m-menu_dot" />}
          {props.title}
        </span>
      </Link>

      {props.children}
    </li>
  );
};

interface SubMenuCardItemProps extends SubMenuItemProps {
  cardSrc: string;
  cssClass?: string;
}

export const SubMenuCardItem: React.FC<SubMenuCardItemProps> = props => {
  const onClickSubMenuCardItem = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const contentTarget = e.currentTarget.closest('.m-menu_content') as HTMLDivElement;
    onToggleMenuContent(contentTarget);
  }, []);

  return (
    <li className="m-menu_submenuitem">
      <Link className="m-menu_linkimage" to={props.to as string}>
        <Image src={props.cardSrc} alt={props.title} />
      </Link>

      <Link to={props.to as string} onClick={onClickSubMenuCardItem}>
        <span className="m-menu_submenuitem_wrapper">
          {props.cssClass && props.cssClass.includes('new_red') && <div className="m-menu_dot" />}
          {props.title}
        </span>
      </Link>
    </li>
  );
};
