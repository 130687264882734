import produce from 'immer';
import { GET_SOLUTION_SUCCESS, GET_SOLUTION_MORE_SUCCESS, SolutionStateType, ActionType } from 'store/types/solution';

const initialState: SolutionStateType = {
  data: [],
  message: '',
  success: false,
  meta: {
    limit: 0,
    page: 0,
    total: 0,
    totalPages: 0,
  },
  loaded: false,
};

export const solutionReducer = produce((draft: SolutionStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_SOLUTION_SUCCESS:
      draft = {
        ...action.payload,
        loaded: true,
      };
      return draft;

    case GET_SOLUTION_MORE_SUCCESS:
      draft = {
        ...action.payload,
        data: [...draft.data, ...action.payload.data],
        loaded: true,
      };
      return draft;
    default:
      return { ...draft };
  }
});
