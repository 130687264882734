import produce from 'immer';
import { PRODUCT_SUCCESS, PRODUCT_TYPE_SUCCESS, ProductStateType, ActionType } from 'store/types/product/index';

const initialState: ProductStateType = {
  productType: {
    data: [],
    message: '',
    success: false,
    loaded: false,
  },
  loaded: false,
};

export const productReducer = produce((draft: ProductStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case PRODUCT_SUCCESS:
      draft = {
        ...action.payload,
        productType: {
          ...action.payload.productType,
          loaded: true,
        },
        loaded: true,
      };
      return draft;
    case PRODUCT_TYPE_SUCCESS:
      draft.productType = action.payload.productType;
      draft.productType.loaded = true;
      return draft;
    default:
      return draft;
  }
});
