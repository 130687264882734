export const NewsCategoryPrefix = {
  vi: 'danh-muc-tin-tuc',
  en: 'news-category',
};

export const GuidesCategoryPrefix = {
  vi: 'thong-tin-huong-dan',
  en: 'guides-category',
};

// NOTE: The default language must be set last
export default {
  APP: {
    en: '/en',
    vi: '/',
  },
  WTB: {
    en: '/en/where-to-buy',
    vi: '/mua-o-dau',
  },
  SLT: {
    en: '/en/solutions',
    vi: '/giai-phap',
  },
  CategorySLT: {
    en: '/en/category-solutions',
    vi: '/danh-muc-giai-phap',
  },
  PN: {
    en: '/en/partners',
    vi: '/doi-tac',
  },
  PNTN: {
    en: '/en/training',
    vi: '/huan-luyen',
  },
  PNTNMSC: {
    en: '/en/membership-card',
    vi: '/the-thanh-vien-daikin',
  },
  PNTNCTFC: {
    en: '/en/certificate',
    vi: '/chung-chi',
  },
  PNDD: {
    en: '/en/why-become-a-daikin-dealer',
    vi: '/ly-do-nen-tro-thanh-dai-ly-daikin',
  },
  PNSVC: {
    en: '/en/service-courses-training-schedule',
    vi: '/lich-huan-luyen-khoa-hoc-dich-vu',
  },
  PNSC: {
    en: '/en/sales-courses-training-schedule',
    vi: '/lich-huan-luyen-khoa-hoc-ban-hang',
  },
  CT: {
    en: '/en/contact-us',
    vi: '/lien-he',
  },
  SVC: {
    en: '/en/service',
    vi: '/dich-vu',
  },
  SVCFAQ: {
    en: '/en/faqs',
    vi: '/cau-hoi-thuong-gap',
  },
  SVCWRP: {
    en: '/en/warranty-policy',
    vi: '/chinh-sach-bao-hanh',
  },
  SVCSACS: {
    en: '/en/smart-ac-selector',
    vi: '/chon-dieu-hoa-khong-khi-phu-hop',
  },
  SVCECTL: {
    en: '/en/e-catalogue',
    vi: '/catalogue-dien-tu',
  },
  SVCERRC: {
    en: '/en/error-codes',
    vi: '/ma-loi',
  },
  SVCMT: {
    en: '/en/maintenance-service',
    vi: '/dich-vu-bao-tri',
  },
  DCV: {
    en: '/en/discover',
    vi: '/kham-pha',
  },
  DCVCS: {
    en: '/en/case-study',
    vi: '/cau-chuyen-khach-hang',
  },
  DCVTECHCOANDA: {
    en: '/en/coanda-comfort-airflow',
    vi: '/coanda-luong-gio-tien-nghi',
  },
  DCVTECHHC: {
    en: '/en/hybrid-cooling-control-temperature-and-humidity-simultaneously',
    vi: '/hybrid-cooling-dieu-tiet-dong-thoi-do-am-va-nhiet-do',
  },
  DCVTECHR32: {
    en: '/en/r-32-next-generation-refrigerant',
    vi: '/r-32-moi-chat-lanh-the-he-moi',
  },
  DCVTECHHP: {
    en: '/en/technology-heatpump',
    vi: '/cong-nghe-bom-nhiet',
  },
  DCVTECHINVERTER: {
    en: '/en/inverter-technology-for-energy-saving',
    vi: '/cong-nghe-inverter-tiet-kiem-nang-luong',
  },
  DCVTECHSTEAMER: {
    // TODO: en is not available
    en: '/en/cong-nghe-loc-khi-streamer',
    vi: '/cong-nghe-loc-khi-streamer',
  },
  DCVTECHEMZYME: {
    // TODO: en is not available
    en: '/en/phin-loc-khu-mui-enzyme-blue',
    vi: '/phin-loc-khu-mui-enzyme-blue',
  },
  DCVTECHCOOLING: {
    // TODO: en is not available
    en: '/en/lam-lanh-nhanh',
    vi: '/lam-lanh-nhanh',
  },
  DCVTECHDMOBILE: {
    // TODO: en is not available
    en: '/en/ket-noi-thong-minh-dmobile',
    vi: '/ket-noi-thong-minh-dmobile',
  },
  DCVHNSP: {
    en: '/en/hanoi-solution-plaza',
    vi: '/hanoi-solution-plaza',
  },
  DCVPCK: {
    en: '/en/pichonkun',
    vi: '/pichonkun',
  },
  DCVN: {
    en: '/en/news',
    vi: '/tin-tuc',
  },
  // SixOptions: {
  //   en: '/en/6-options',
  //   vi: '/6-lua-chon',
  // },
  DCVNC: {
    en: `/en/${NewsCategoryPrefix['en']}`,
    vi: `/${NewsCategoryPrefix['vi']}`,
  },
  DCVG: {
    en: '/en/experiences',
    vi: '/kinh-nghiem-hay',
  },
  DCVGC: {
    en: `/en/${GuidesCategoryPrefix['en']}`,
    vi: `/${GuidesCategoryPrefix['vi']}`,
  },
  PRD: {
    en: '/en/product',
    vi: '/san-pham',
  },
  ABUS: {
    en: '/en/about-us',
    vi: '/ve-chung-toi',
  },
  ABUSDKVN: {
    en: '/en/daikin-vietnam',
    vi: '/daikin-vietnam',
  },
  ABUSCSR: {
    en: '/en/csr-activities',
    vi: '/trach-nhiem-xa-hoi',
  },
  ABUSHIS: {
    en: '/en/history',
    vi: '/lich-su-phat-trien',
  },
  ABUSFT: {
    en: '/en/daikin-factory',
    vi: '/nha-may-daikin-vietnam',
  },
  ABUSAWS: {
    en: '/en/daikin-award',
    vi: '/giai-thuong',
  },
  ABUSDKGR: {
    en: '/en/daikin-group',
    vi: '/tap-doan-daikin',
  },
  SEARCH: {
    en: '/en/search-results',
    vi: '/ket-qua-tim-kiem',
  },
  '404': {
    en: '/en/404',
    vi: '/404',
  },
  CMP: {
    en: '/en/comparison',
    vi: '/so-sanh',
  },
  TACD: {
    en: '/en/terms-and-conditions',
    vi: '/dieu-khoan-su-dung',
  },
  SITEMAP: {
    en: '/en/sitemap',
    vi: '/so-do-trang',
  },
  AIRPURIFIER: {
    en: '/en/fresh-and-pure-daikin-air',
    vi: '/fresh-and-pure-daikin-air',
  },
} as const;
