import React, { useMemo } from 'react';
import { getI18n } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useAppSelector } from 'store';
import { MetaDataType } from 'services/common';
import { TranslateType, OpenGraphContent } from 'services/system';
import APIPATHES from 'constants/api-pathes';

export const Seo: React.FC<{ metaData?: MetaDataType }> = ({ metaData }) => {
  const i18nInstance = getI18n();
  const { system } = useAppSelector(state => state.system);
  const dataSystem = useMemo(() => (system && i18nInstance ? system.data : undefined), [i18nInstance, system]);

  const openGraphContent = (dataSystem?.openGraph?.content as OpenGraphContent)?.[
    i18nInstance.language as keyof TranslateType
  ];

  if (typeof metaData === 'undefined' || metaData === null) return null;

  return (
    <Helmet defer={false}>
      <meta charSet="utf-8" key="meta-charset" />
      {(metaData?.extraData?.canonical || dataSystem?.canonical?.content) && (
        <link
          rel="canonical"
          href={
            metaData.extraData?.canonical ||
            (dataSystem?.canonical?.content as TranslateType)[i18nInstance.language as keyof TranslateType]
          }
        />
      )}
      {(metaData.keywords || dataSystem?.seoKeyword?.content) && (
        <meta
          name="keywords"
          key="keywords"
          content={
            metaData.keywords ||
            (dataSystem?.seoKeyword?.content as TranslateType)[i18nInstance.language as keyof TranslateType]
          }
        />
      )}
      {(metaData.description || dataSystem?.seoDescription?.content) && (
        <meta
          name="description"
          key="description"
          content={
            metaData.description ||
            (dataSystem?.seoDescription?.content as TranslateType)[i18nInstance.language as keyof TranslateType]
          }
        />
      )}
      {(metaData.extraData?.ogTitle || openGraphContent?.ogTitle) && (
        <meta property="og:title" content={metaData.extraData?.ogTitle || openGraphContent?.ogTitle} />
      )}
      {(metaData.extraData?.ogDescription || openGraphContent?.ogDescription) && (
        <meta
          property="og:description"
          content={metaData.extraData?.ogDescription || openGraphContent?.ogDescription}
        />
      )}
      {(metaData.extraData?.ogImage || openGraphContent?.ogImage) && (
        <meta
          property="og:image"
          content={`${APIPATHES['STORAGE']}${metaData.extraData?.ogImage || openGraphContent?.ogImage}`}
        />
      )}
      {(metaData.extraData?.ogType || openGraphContent?.ogType) && (
        <meta property="og:type" content={metaData.extraData?.ogType || openGraphContent?.ogType} />
      )}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image:width" content="720" />
      <meta property="og:image:height" content="480" />
      {(metaData.title || dataSystem?.seoTitle?.content) && (
        <title key="title">
          {metaData.title ||
            (dataSystem?.seoTitle?.content as TranslateType)[i18nInstance.language as keyof TranslateType]}
        </title>
      )}
    </Helmet>
  );
};
