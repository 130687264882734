import http from 'services/http';

export interface CatalogueResponse {
  data: Array<{
    active: number;
    file: string;
    id: number;
    name: string;
    thumbnail: string;
    productType: {
      name: string;
    };
  }>;
  message: string;
  success: boolean;
  meta: {
    limit: number;
    page: number;
    total: number;
    totalPages: number;
  };
}

export interface CataloguePageType {
  title: string;
  pageMetaData: {
    bannerTagTitleDescriptionImageSearch100: {
      tag: string;
      heading: string;
      description: string;
      bgImage: string;
      tabletBgImage: string;
      mobileBgImage: string;
      headingSearch: string;
      placeholderSearch: string;
      suggestSearch: string;
    };
  };
}

type FetchCatalogueRequest = {
  page: number;
  limit: number;
  keyword?: string;
  product_type_ids?: string;
};

export const getCatalogueList = async (params: FetchCatalogueRequest): Promise<CatalogueResponse> => {
  const { data } = await http.get('/catalogues', { params });
  return data;
};
