import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CategoriesResponse,
  getCategoriesGuides,
  getGuidesByCategory,
  getGuidesList,
  GuidesResponse,
} from 'services/discovery/guides';
import { GUIDES_LIST, GUIDES_LIST_SUCCESS, GUIDES_PAGE, GUIDES_PAGE_SUCCESS } from 'store/types/discovery/guides';

function* guidesPage() {
  const [categoriesGuidesResponse] = (yield all([
    call(getCategoriesGuides),
    // call(getGuidesList, { page: 1, limit: 7 }),
  ])) as Array<CategoriesResponse | GuidesResponse>;

  yield put({
    type: GUIDES_PAGE_SUCCESS,
    payload: {
      data: {
        categories: categoriesGuidesResponse,
        // guides: {
        //   all: guidesByCategoryResponse,
        // },
      },
    },
  });
}

function* watchGuidesPage() {
  yield takeLatest(GUIDES_PAGE, guidesPage);
}

function* guides(action: { type: string; payload: { category_type: string; page: number, limit: 7 } }) {
  if (action.payload.category_type !== 'all') {
    const data = yield call(getGuidesByCategory, action.payload.category_type);
    yield put({ type: GUIDES_LIST_SUCCESS, payload: { data, category: action.payload.category_type } });
  } else {
    const data = yield call(getGuidesList, { page: action.payload.page, limit: action.payload.limit });
    yield put({ type: GUIDES_LIST_SUCCESS, payload: { data, category: action.payload.category_type } });
  }
}

function* watchGuides() {
  yield takeLatest(GUIDES_LIST, guides);
}

export default function* guidesSaga() {
  yield all([watchGuidesPage(), watchGuides()]);
}
