import { FloatingBannerData } from 'services/common';

export const GET_FLOATING_BANNER = 'GET_FLOATING_BANNER';
export const GET_FLOATING_BANNER_SUCCESS = 'GET_FLOATING_BANNER_SUCCESS';
export const SET_TOGGLE_FLOATING_BANNER = 'SET_TOGGLE_FLOATING_BANNER';

export interface FloatingBannerStateType {
  loaded: boolean;
  data?: FloatingBannerData,
  isOpen: boolean;
}

export type ActionType = {
  type: typeof GET_FLOATING_BANNER_SUCCESS;
  payload: FloatingBannerData;
};
