import React, { useCallback, forwardRef } from 'react';
import { Icon } from 'components/atoms/icon';
import { mapModifiers } from 'lib/component';

type Modifier = 'round';
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: { groupName: string; optionNames: string[] }[];
  onClick?: () => void;
  onSelectOption?: (option: string) => void;
  modifiers?: Modifier | Modifier[];
}

export const AutoComplete = forwardRef<HTMLInputElement, Props>(
  ({ options, onClick, onSelectOption, modifiers, ...otherProps }, ref) => {
    const onSelectOptionFactory = useCallback((option: string) => onSelectOption && onSelectOption(option), [
      onSelectOption,
    ]);

    return (
      <div className={mapModifiers('a-autocomplete', modifiers)}>
        <input {...otherProps} ref={ref} />
        {options && options.length > 0 && (
          <div className="a-autocomplete_result">
            {options.map(({ groupName, optionNames }, groupIndex) => (
              <React.Fragment key={groupIndex}>
                <span className="a-autocomplete_resultgroup">{groupName}</span>
                <ul>
                  {optionNames.map((option, index) => (
                    <li className="a-autocomplete_resultitem" key={index} onClick={() => onSelectOptionFactory(option)}>
                      {option}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        )}
        <Icon iconName="search-black" modifiers="medium" onClick={onClick} />
      </div>
    );
  }
);

AutoComplete.displayName = 'AutoComplete';
