import http from 'services/http';

export interface ServicesPageType {
  id: number;
  name: string;
  pageMetaData: {
    servicesSectionService0: {
      heading: string;
      description: string;
      bannerTitle: string;
      bannerImage: string;
      bannerDescription: string;
    };
    servicesSectionCallCenter0: {
      heading: string;
      image: string;
      blocks: {
        [key: number]: {
          title: string;
          description: string;
        };
      };
    };
    servicesSectionAccessary0: {
      heading: string;
      image: string;
      description: string;
      button: {
        link: string;
        text: string;
      };
    };
  };
}

export interface MenuServiceType {
  title: string;
  image: string;
  imageHover: string;
  imageChild: string;
  reference: {
    slug: string;
  };
  link: string;
  target: string;
  type: string;
}

export interface MenuServicesResponse {
  data: {
    [key: number]: MenuServiceType;
  };
  message: string;
  success: boolean;
}

export const getMenuServices = async (): Promise<MenuServicesResponse> => {
  const { data } = await http.get('/get-menu/services_menu');
  return data;
};
