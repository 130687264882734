import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { PRODUCT, ProductStateType, PRODUCT_SUCCESS, PRODUCT_TYPE, PRODUCT_TYPE_SUCCESS } from 'store/types/product';
import { ActionType } from 'store/types/product';
import { getProductTypeList, ProductTypeResponse } from 'services/product';
import { getSolution, SolutionsResponse } from 'services/solution';

function* productType() {
  const productTypeRes = yield call(getProductTypeList);
  yield put({ type: PRODUCT_TYPE_SUCCESS, payload: { productType: productTypeRes } });
}

function* watchProductType() {
  yield takeEvery(PRODUCT_TYPE, productType);
}

function* product() {
  const { productType: productTypeState } = (yield select(state => state.product)) as ProductStateType;
  const [productTypeRes, solutionRelative] = (yield all([
    call(productTypeState.loaded ? () => productTypeState : getProductTypeList),
    call(getSolution, { page: 1, limit: 4, show_in_product_page: true }),
  ])) as Array<ProductTypeResponse | SolutionsResponse>;

  yield put({ type: PRODUCT_SUCCESS, payload: { productType: productTypeRes, solutionRelative } } as ActionType);
}

function* watchProduct() {
  yield takeEvery(PRODUCT, product);
}

export default function* productSaga() {
  yield all([watchProduct(), watchProductType()]);
}
