import { getI18n } from 'react-i18next';
import PATHES from 'constants/pathes';

// Get the path through the key of the PATHES. By default, it will use the current language of the app
export function getPath(key: keyof typeof PATHES, lng?: string) {
  const i18nInstance = getI18n();
  return i18nInstance ? PATHES[key][(lng as 'vi' | 'en') || (i18nInstance.language as 'vi' | 'en')] : '#';
}

// Get the pathes through the key of the PATHES
export function getPathes(key: keyof typeof PATHES) {
  return Object.values(PATHES[key]);
}

export function getSwitchedProductPath(key: keyof typeof PATHES, lng?: string) {
  const i18nInstance = getI18n();
  const languageTemp: 'vi' | 'en' = (lng as 'vi' | 'en') || (i18nInstance.language as 'vi' | 'en');
  let result = i18nInstance ? PATHES[key][languageTemp] : '#';
  const arrTemp = result.split('/');
  result = arrTemp.filter(item => item !== '' && item !== 'en').join('/');
  return result;
}

// Get the correct path through the current language.
// NOTE: The linkUrl must begin with the `/` character
export function getLink(linkUrl: string) {
  if (linkUrl === null) return '#';

  const i18nInstance = getI18n();
  return linkUrl?.includes('http')
    ? linkUrl
    : i18nInstance
    ? i18nInstance.language === 'vi'
      ? linkUrl
      : `/${i18nInstance.language}${linkUrl}`
    : '#';
}

// Get `slugPage` through the `pathname`
export function getSlugPage(pathname?: string) {
  if (pathname) {
    const lastCharacter = pathname.substring(pathname.length - 1);
    const pathnameClean = lastCharacter === '/' ? pathname.substring(0, pathname.length - 1) : pathname;

    if (pathnameClean) {
      const pathNameSplit = pathnameClean.split('/');
      return pathNameSplit[pathNameSplit.length - 1];
    }
  }

  return 'home';
}
