import React from 'react';
import { Icon, IconName } from 'components/atoms/icon';
import { Link } from 'components/utils/link';
import { mapModifiers } from 'lib/component';

interface Props {
  href?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  target?: string;
  rel?: string;
  onClick?: () => void;
  icon?: IconName;
  useNative?: boolean;
  disabled?: boolean;
}

export const Textlink: React.FC<Props> = props => {
  if (props.href && !props.disabled) {
    return (
      <Link
        className="a-textlink"
        useNative={props.useNative}
        to={props.href}
        state={props.state}
        rel={props.rel}
        target={props.target}
      >
        {props.children}
        {props.icon && <Icon iconName={props.icon} />}
      </Link>
    );
  }

  return (
    <div className={mapModifiers('a-textlink', props.disabled && 'disabled')} onClick={props.onClick}>
      {props.children}
      {props.icon && <Icon iconName={props.icon} />}
    </div>
  );
};
