import http from 'services/http';
import { SeriesType } from 'services/product';

export interface SmartACListResponse {
  message: string;
  success: boolean;
  loaded: boolean;
  data?: {
    btu: number;
    data: {
      name: string;
      templateDisplay: number;
      isCompare: number;
      series: Array<SeriesType>;
    };
  };
}

export interface SmartAcSelectorPageType {
  title: string;
  slug: string;
  pageMetaData: {
    bannerTagTitleDescriptionImageSearch0: {
      tag: string;
      heading: string;
      description: string;
      bgImage: string;
      mobileBgImage: string;
      headingSearch: string;
      placeholderSearch: string;
      suggestSearch: string;
    };
    smartACSelectorApplication0: {
      headingApplication: string;
    };
    smartACSelectorRelativeGuide0: {
      headingRelative: string;
      contentRelative: string;
    };
  };
}

export type SmartAcSelectorRequest = {
  coolingType: number;
  roomType: number;
  sunlightType: number;
  roofType: number;
  length: number;
  width: number;
  setupType: number;
};

export const sendSmartAc = async (params: SmartAcSelectorRequest): Promise<SmartACListResponse> => {
  const { data } = await http.get('/smart-ac', { params });
  return data;
};
