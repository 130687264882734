import produce from 'immer';
import {
  ERROR_SYSTEM,
  CommonStateType,
  ActionType,
  PAGE_SUCCESS,
  PAGE_NOT_FOUND,
  PAGE_CLEAR_NOT_FOUND,
} from 'store/types/common';

const initialSate: CommonStateType = {
  isErrorSystem: false,
  pages: {},
  isNotFound: false,
};

export const commonReducer = produce((draft: CommonStateType = initialSate, action: ActionType) => {
  switch (action.type) {
    case ERROR_SYSTEM:
      draft.isErrorSystem = true;
      return draft;
    case PAGE_SUCCESS:
      draft.pages = action.payload?.page ? { ...draft.pages, ...action.payload?.page } : draft.pages;
      return draft;
    case PAGE_NOT_FOUND:
      draft.pages = action.payload?.page ? { ...draft.pages, ...action.payload?.page } : draft.pages;
      draft.isNotFound = true;
      return draft;
    case PAGE_CLEAR_NOT_FOUND:
      draft.isNotFound = false;
      return draft;
    default:
      return draft;
  }
});
