import { HeaderResponse } from 'services/header';

export const HEADER = 'HEADER';
export const HEADER_LOADING = 'HEADER_LOADING';
export const HEADER_END_LOADING = 'HEADER_END_LOADING';
export const HEADER_SUCCESS = 'HEADER_SUCCESS';

export interface HeaderCombineType {
  main: HeaderResponse;
  second: HeaderResponse;
}

export interface HeaderStateType extends HeaderCombineType {
  loaded: boolean;
  loading?: boolean;
}

export type ActionType = {
  type: string;
  payload: HeaderCombineType;
};
