import React from 'react';
import ReactModal from 'react-modal';
import { Icon, IconSize, IconName } from 'components/atoms/icon';
import { mapModifiers } from 'lib/component';
import { Checkbox } from 'components/atoms/checkbox';
import { Image } from 'components/atoms/image';
import { Link } from 'components/utils/link';

interface Props {
  isOpen: boolean;
  fullbox?: boolean;
  fullscreenMobile?: boolean;
  style?: ReactModal.Styles;
  isClosable?: boolean;
  isCentered?: boolean;
  closeIconSize?: IconSize;
  backgroundImageSrc?: string;
  backgroundImageAlt?: string;
  hasCheckBox?: boolean;
  checkBoxTitle?: string;
  closeIcon?: IconName;
  closeModal?: () => void;
  onChangeCheckBox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAfterClose?: () => void;
  backgroundHref?: string;
  backgroundTarget?: string;
}

export const Modal: React.FC<Props> = props => {
  return (
    <div className="o-modal">
      <ReactModal
        isOpen={props.isOpen}
        overlayClassName="o-modal_overlay"
        onRequestClose={props.closeModal}
        portalClassName="o-modal_portal"
        className={mapModifiers(
          'o-modal_content',
          props.fullbox && 'fullbox',
          props.fullscreenMobile && 'fullscreenMobile',
          props.isCentered && 'centered',
          props.backgroundImageSrc && 'hasbackgroundimage',
          props.hasCheckBox && 'hascheckbox'
        )}
        ariaHideApp={false}
        style={props.style || undefined}
        onAfterClose={props.onAfterClose}
      >
        <div className="o-modal_body">
          {props.isClosable && (
            <div className="o-modal_close" onClick={props.closeModal}>
              <Icon modifiers={props.closeIconSize} iconName={props.closeIcon as IconName} />
            </div>
          )}
          {props.children}
          {props.backgroundImageSrc &&
            (props.backgroundHref ? (
              <Link to={props.backgroundHref} target={props.backgroundTarget}>
                <Image src={props.backgroundImageSrc} alt={props.backgroundImageAlt || ''} />
              </Link>
            ) : (
              <Image src={props.backgroundImageSrc} alt={props.backgroundImageAlt || ''} />
            ))}
        </div>

        {props.hasCheckBox && <Checkbox title={props.checkBoxTitle || ''} onChange={props.onChangeCheckBox} />}
      </ReactModal>
    </div>
  );
};

Modal.defaultProps = {
  isClosable: true,
  closeIcon: 'close',
};
