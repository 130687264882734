import produce from 'immer';
import { PichonkunResponse } from 'services/discovery/pichonkun';
import {
  PICHONKUN_PAGE_SUCCESS,
  PICHONKUN_LIST_SUCCESS,
  PichonkunPageStateType,
  ActionType,
} from 'store/types/discovery/pichonkun/index';

const initialState: PichonkunPageStateType = {
  categories: {
    message: '',
    success: false,
    data: [],
  },
  listPichonkun: {},
  loaded: false,
};

export const pichonkunReducer = produce((draft: PichonkunPageStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case PICHONKUN_PAGE_SUCCESS:
      draft = {
        ...(action.payload.data as PichonkunPageStateType),
        loaded: true,
      };
      return draft;
    case PICHONKUN_LIST_SUCCESS:
      draft.listPichonkun[action.payload.category as number] = {
        ...(action.payload.data as PichonkunResponse),
        data: [
          ...draft.listPichonkun[action.payload.category as number].data,
          ...(action.payload.data as PichonkunResponse).data,
        ],
      };
      return draft;
    default:
      return draft;
  }
});
