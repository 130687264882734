import { ProductType, SeriesIdResponse } from 'services/product';

export const GET_SERIES_ID = 'GET_SERIES_ID';
export const GET_SERIES_ID_SUCCESS = 'GET_SERIES_ID_SUCCESS';
export const PUT_PRODUCT_SELECTED = 'PUT_PRODUCT_SELECTED';
export const PUT_PRODUCT_SELECTED_SUCCESS = 'PUT_PRODUCT_SELECTED_SUCCESS';

export interface ProductSelectedType {
  seriesType: string;
  products: Array<{ product: ProductType; series: { name: string; thumbnail: string } }>;
}

export interface ComparisonStateType {
  currentSeries?: SeriesIdResponse;
  productsCompare?: ProductSelectedType;
}

export type ActionType = {
  type: typeof GET_SERIES_ID_SUCCESS | typeof PUT_PRODUCT_SELECTED_SUCCESS;
  payload: SeriesIdResponse | ProductSelectedType;
};
