import http from 'services/http';

export interface DistributionCategoriesType {
  message: string;
  success: boolean;
  loaded: boolean;
  data: Array<{
    id: number;
    name: string;
  }>;
}

export type MetaPage = {
  limit: number;
  page: number;
  total: number;
  totalPages: number;
};

export interface WhereToBuyDataType {
  longitude: number;
  latitude: number;
  address: string;
  id: number;
  phone: string;
  name: string;
  cityId: number;
  linkWeb: string | null;
}

export interface WhereToBuyType {
  message: string;
  success: boolean;
  data: Array<WhereToBuyDataType>;
  meta: MetaPage;
}

export interface WhereToBuyPageType {
  name: string;
  slug: string;
  title: string;
  pageMetaData: {
    bannerTitleDescriptionImage0: {
      bgImage: string;
      description: string;
      heading: string;
      mobileBgImage: string;
      tabletBgImage: string;
    };
    whereToBuySectionFindAProshop0: {
      description: string;
      heading: string;
    };
    whereToBuySectionVisit0: {
      image: string;
      mobileImage: string;
      tabletImage: string;
      url: string;
    };
  };
}

export interface CityDataType {
  id: number;
  name: string;
}

export interface CitiesType {
  message: string;
  success: boolean;
  loaded: boolean;
  data: Array<CityDataType>;
}

export const getDistributionCategoriesList = async (): Promise<DistributionCategoriesType> => {
  const { data } = await http.get('/distribution-categories');
  return data;
};

type DistributionListPayload = {
  type?: number;
  isCompaign?: number;
  cityId?: number;
  keyword?: string;
  limit?: number;
  currentPage?: number;
};

export const getDistributionList = async ({
  type,
  isCompaign,
  cityId,
  keyword,
  limit = 5,
  currentPage = 1,
}: DistributionListPayload): Promise<WhereToBuyType> => {
  // NOTE: Set the limit to 1000 as it is not necessary for pagination
  const { data } = await http.get('/distributions', {
    params: { limit: limit, page: currentPage, type, is_compaign: isCompaign, city_id: cityId, keyword },
  });
  return data;
};

export const getCities = async (isCompaign?: number): Promise<CitiesType> => {
  const { data } = await http.get('/cities', {
    params: {
      country_id: 1,
      is_compaign: isCompaign,
    },
  });
  return data;
};
