import { all, call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { SolutionRequest, getSolution } from 'services/solution';
import { GET_SOLUTION_SUCCESS, GET_SOLUTION, GET_SOLUTION_MORE, GET_SOLUTION_MORE_SUCCESS } from 'store/types/solution';
import { ActionType, SolutionStateType } from 'store/types/solution';

function* solution(action: { type: string; payload: SolutionRequest }) {
  const solutionList = yield call(getSolution, { ...action.payload });
  yield put({ type: GET_SOLUTION_SUCCESS, payload: solutionList } as ActionType);
}

function* watchSolution() {
  yield takeEvery(GET_SOLUTION, solution);
}

function* solutionMore(action: { type: string; payload: SolutionRequest }) {
  const solutionState = (yield select(state => state.solution)) as SolutionStateType;
  const solutionList = yield call(getSolution, { ...action.payload, page: solutionState.meta.page + 1 });
  yield put({ type: GET_SOLUTION_MORE_SUCCESS, payload: solutionList });
}

function* watchSolutionMore() {
  yield takeLatest(GET_SOLUTION_MORE, solutionMore);
}

export default function* solutionSaga() {
  yield all([watchSolution(), watchSolutionMore()]);
}
