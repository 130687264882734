import { Backtotop } from 'components/atoms/backtotop';
import { Footer } from 'components/organisms/footer';
import { Header } from 'components/organisms/header';
import { Main } from 'components/organisms/main';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const Layout: React.FC = props => {
  const { pathname } = useLocation();
  return (
    <div className="t-layout">
      <Header />
      <Main>{props.children}</Main>
      <Footer />
      {!['/fresh-and-pure-daikin-air'].includes(pathname) && <Backtotop />}
    </div>
  );
};
