import http from 'services/http';

export interface FAQCategoriesResponse {
  success: boolean;
  message: string;
  data: Array<{
    id: number;
    name: string;
    slug: string;
    icon: string;
    iconHover: string;
  }>;
}

export interface FAQListByCategorySlugResponse {
  success: boolean;
  message: string;
  data: Array<{
    id: number;
    answer: string;
    question: string;
  }>;
}

export interface FaqPageType {
  title: string;
  slug: string;
  pageMetaData: {
    bannerTagTitleDescriptionImageSearch0: {
      tag: string;
      heading: string;
      description: string;
      bgImage: string;
      mobileBgImage: string;
      headingSearch: string;
      placeholderSearch: string;
      suggestSearch: string;
    };
    fAQSectionRelativeGuide0: {
      headingRelative: string;
      contentRelative: string;
    };
  };
}

export const getFaqCategoryList = async (): Promise<FAQCategoriesResponse> => {
  const { data } = await http.get('/faq-categories');
  return data;
};

export const getFaqListByCategorySlug = async (slug: string): Promise<FAQListByCategorySlugResponse> => {
  // NOTE: Set the limit to 1000 as it is not necessary for pagination
  const { data } = await http.get(`/faqs/category-slug/${slug}`, { params: { limit: 1000, page: 1 } });
  return data;
};
