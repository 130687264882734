import { SolutionsResponse } from 'services/solution';

export const GET_SOLUTION = 'GET_SOLUTION';
export const GET_SOLUTION_SUCCESS = 'GET_SOLUTION_SUCCESS';
export const GET_SOLUTION_MORE = 'GET_SOLUTION_MORE';
export const GET_SOLUTION_MORE_SUCCESS = 'GET_SOLUTION_MORE_SUCCESS';

export interface SolutionStateType extends SolutionsResponse {
  loaded: boolean;
}

export type ActionType = {
  type: typeof GET_SOLUTION_MORE_SUCCESS | typeof GET_SOLUTION_SUCCESS;
  payload: SolutionsResponse;
};
