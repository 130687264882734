import produce from 'immer';
import {
  SMART_AC_SUCCESS,
  SEND_SMART_AC_SUCCESS,
  RESET,
  SmartAcStateType,
  ActionType,
} from 'store/types/services/smart_ac_selector';

const initialState: SmartAcStateType = {
  smartAClist: {
    message: '',
    success: false,
    loaded: false,
  },
  solutionRelative: {
    data: [],
    message: '',
    meta: {
      limit: 0,
      page: 0,
      total: 0,
      totalPages: 0,
    },
    success: false,
  },
  guides: {
    data: [],
    message: '',
    success: false,
    loaded: false,
    meta: {
      limit: 0,
      page: 0,
      total: 0,
      totalPages: 0,
    },
  },

  loaded: false,
};

export const smartAcSelectorReducer = produce((draft: SmartAcStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case SMART_AC_SUCCESS:
      draft.guides = action.payload.guides;
      draft.solutionRelative = action.payload.solutionRelative;
      draft.loaded = true;
      return draft;
    case SEND_SMART_AC_SUCCESS:
      draft.smartAClist = action.payload.smartAcList;
      draft.smartAClist.loaded = true;
      return draft;
    case RESET:
      draft.smartAClist = initialState.smartAClist;
      return draft;
    default:
      return draft;
  }
});
