/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as log from 'loglevel';
import { action } from 'store';
// import { LOCAL_STORAGE } from 'utils/constants';
import { getLanguageFromPath } from 'lib/utils';

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfigCustom): Promise<R>;
    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfigCustom): Promise<R>;
    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfigCustom): Promise<R>;
    head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfigCustom): Promise<R>;
    options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfigCustom): Promise<R>;
    post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfigCustom): Promise<R>;
    put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfigCustom): Promise<R>;
    patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfigCustom): Promise<R>;
  }
}

const http = axios.create({
  // https://stackoverflow.com/questions/48378337/create-react-app-not-picking-up-env-files/48378498#48378498
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: getLanguageFromPath(window.location.pathname),
  },
  params: {
    locale: getLanguageFromPath(window.location.pathname),
  },
});

type AxiosRequestConfigCustom = AxiosRequestConfig & {
  requestStartTime?: number;
  shouldCheckErrorPage?: boolean;
};

http.interceptors.request.use(
  (config: AxiosRequestConfigCustom) => {
    log.info(`[API]request ${config['baseURL']}${config['url']}`, config);
    // https://github.com/axios/axios/issues/695#issuecomment-362383207
    config.requestStartTime = Date.now();

    return config;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: AxiosError<any>) => {
    log.error(`[API]request error`, error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (response: AxiosResponse<any>) => {
    log.info(
      `[API]response ${response.config['baseURL']}${response.config['url']} in ${
        Date.now() - ((response.config as AxiosRequestConfigCustom).requestStartTime as number)
      }ms`,
      response
    );
    return response;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: AxiosError<any> & { config: AxiosRequestConfigCustom }) => {
    log.error(`[API]response error`, error);

    if (error.response?.status === 404 && error.config.shouldCheckErrorPage) {
      action({ type: 'REDIRECT', payload: window.location.pathname });
    }

    if (error.response?.status === 500) {
      action({ type: 'ERROR_SYSTEM' });
    }

    return Promise.reject(error);
  }
);

export default http;
