import React, { useCallback, useEffect, useRef } from 'react';
import { Icon } from 'components/atoms/icon';
import { useAppSelector } from 'store';
import { mapModifiers } from 'lib/component';

export const Backtotop: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    floatingBanner: { data, isOpen },
  } = useAppSelector(state => ({ floatingBanner: state.floatingBanner }));

  useEffect(() => {
    const backToTopTarget = ref.current;

    if (!backToTopTarget) return undefined;

    const onToggleBackToTop = () => {
      const { scrollHeight, clientHeight } = document.documentElement;
      // scroll to center of page
      if (document.documentElement.scrollTop >= Math.abs((scrollHeight - clientHeight)) / 2) {
        backToTopTarget.style.display = 'flex';
      } else {
        backToTopTarget.style.display = 'none';
      }
    };

    window.addEventListener('scroll', onToggleBackToTop);

    return () => {
      window.removeEventListener('scroll', onToggleBackToTop);
    };
  });

  const onScrollToTop = useCallback(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);

  return (
    <div onClick={onScrollToTop} ref={ref} className={mapModifiers('a-backtotop', data ? 'hasFloating' : '', isOpen ? 'floatingBannerOpen' : '')}>
      <Icon iconName="back-to-top" modifiers="large-lv2" />
    </div>
  );
};
