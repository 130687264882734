import React from 'react';
import { mapModifiers } from 'lib/component';

export type IconName =
  | 'arrow-left-black'
  | 'arrow-left-white'
  | 'arrow-left-blue'
  | 'arrow-right-black'
  | 'arrow-right-blue'
  | 'arrow-right-white'
  | 'arrow-down-white'
  | 'arrow-back-black'
  | 'chevron-right-blue'
  | 'chevron-right-white'
  | 'chevron-down-blue'
  | 'chevron-round-down-white'
  | 'sort-right-solid-gray'
  | 'search-black'
  | 'search-grey'
  | 'search-plus-white'
  | 'plus'
  | 'plus-black'
  | 'play-white'
  | 'minus'
  | 'wind-solid'
  | 'download-white'
  | 'download-solid'
  | 'download-solid-blue'
  | 'download-solid-white'
  | 'question-circle-solid'
  | 'question-circle-blue'
  | 'exclamation-triangle-solid'
  | 'award-solid'
  | 'tools-solid'
  | 'facebook-white'
  | 'youtube-white'
  | 'accessories-policy'
  | 'conduct-policy'
  | 'guide-policy'
  | 'other-service-policy'
  | 'setting-policy'
  | 'tick-policy'
  | 'asset-download'
  | 'circle-arrow-right-blue'
  | 'circle-arrow-right-white'
  | 'location-map'
  | 'close'
  | 'close-square'
  | 'close-grey'
  | 'cricle-location'
  | 'cricle-location-grey'
  | 'continuous-growth'
  | 'speed-up'
  | 'start-up'
  | 'call-made'
  | 'error'
  | 'check-circle-solid'
  | 'back-to-top'
  | 'back-to-bottom'
  | 'checked-white'
  | 'sort-right-solid-white'
  | 'gradient-close'
  | 'bag'
  | 'gps'
  | 'icon-360'
  | 'mail'
  | 'mail-white'
  | 'phone-white'
  | 'collapse-arrow';

export type IconSize = 'medium' | 'large' | 'small' | 'large-lv2';

interface Props {
  iconName: IconName;
  modifiers?: IconSize;
  onClick?: () => void;
}

export const Icon: React.FC<Props> = props => (
  <span
    className={mapModifiers('a-icon', props.modifiers ? [props.modifiers, props.iconName] : props.iconName)}
    onClick={props.onClick}
  />
);
