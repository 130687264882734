import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_SERIES_ID,
  GET_SERIES_ID_SUCCESS,
  PUT_PRODUCT_SELECTED,
  PUT_PRODUCT_SELECTED_SUCCESS,
  ComparisonStateType,
  ProductSelectedType,
} from 'store/types/comparison';
import { getSeriesId } from 'services/product';

function* seriesId(action: { type: typeof GET_SERIES_ID; payload: { id: number; btu?: number } }) {
  const comparisonState = (yield select(state => state.comparison)) as ComparisonStateType;
  if (comparisonState.currentSeries?.data.id !== action.payload.id) {
    yield put({ type: GET_SERIES_ID_SUCCESS, payload: undefined });
    const SeriesIdRes = yield call(getSeriesId, action.payload.id, action.payload.btu);
    yield put({ type: GET_SERIES_ID_SUCCESS, payload: SeriesIdRes });
  }
}

function* productSelected(action: { type: typeof PUT_PRODUCT_SELECTED; payload: ProductSelectedType }) {
  yield put({ type: PUT_PRODUCT_SELECTED_SUCCESS, payload: action.payload });
}

function* watchSeriesId() {
  yield takeLatest(GET_SERIES_ID, seriesId);
}

function* watchProductSelected() {
  yield takeLatest(PUT_PRODUCT_SELECTED, productSelected);
}

export default function* comparisonSaga() {
  yield all([watchSeriesId(), watchProductSelected()]);
}
