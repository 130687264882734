import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  SMART_AC,
  SMART_AC_SUCCESS,
  SEND_SMART_AC,
  SEND_SMART_AC_SUCCESS,
  RESET,
} from 'store/types/services/smart_ac_selector';
import { getSolution, SolutionsResponse } from 'services/solution';
import { getGuidesList, GuidesResponse } from 'services/discovery/guides';
import { sendSmartAc, SmartAcSelectorRequest } from 'services/services/smart_ac_selector';
import { SmartAcStateType } from 'store/types/services/smart_ac_selector';

function* smartAc() {
  const [solutionRelative, guidesResponse] = (yield all([
    call(getSolution, { page: 1, limit: 4, show_in_smart_ac_page: true }),
    call(getGuidesList, { page: 1, limit: 7, show_in_smart_ac_page: true }),
  ])) as Array<SolutionsResponse | GuidesResponse>;

  yield put({ type: SMART_AC_SUCCESS, payload: { solutionRelative, guides: guidesResponse } });
}

function* watchSmartAc() {
  yield takeEvery(SMART_AC, smartAc);
}

function* sendSmartAC(action: { type: string; payload: { params: SmartAcSelectorRequest } }) {
  const smartAcSelectorState = (yield select(state => state.smartAcSelector)) as SmartAcStateType;
  if (smartAcSelectorState.smartAClist.loaded) {
    yield put({ type: RESET });
  }
  const smartAcList = yield call(sendSmartAc, action.payload.params);
  yield put({ type: SEND_SMART_AC_SUCCESS, payload: { smartAcList } });
}

function* watchSendSmartAc() {
  yield takeEvery(SEND_SMART_AC, sendSmartAC);
}

export default function* smartAcSelectorSaga() {
  yield all([watchSmartAc(), watchSendSmartAc()]);
}
