import { all, call, put, takeEvery } from 'redux-saga/effects';
import { GET_MENU_SERVICES, GET_MENU_SERVICES_SUCCESS } from 'store/types/services/index';
import { getMenuServices } from 'services/services';

function* menuService() {
  const res = yield call(getMenuServices);
  yield put({ type: GET_MENU_SERVICES_SUCCESS, payload: res });
}

function* watchMenuService() {
  yield takeEvery(GET_MENU_SERVICES, menuService);
}

export default function* menuServiceSaga() {
  yield all([watchMenuService()]);
}
