import produce from 'immer';
import { GuidesResponse } from 'services/discovery/guides';
import {
  GUIDES_LIST_SUCCESS,
  GUIDES_PAGE_SUCCESS,
  GuidesPageStateType,
  GuidesStateType,
  ActionType,
  GUIDES_LIST,
} from 'store/types/discovery/guides';

const initialState: GuidesStateType = {
  categories: {
    data: [],
    message: '',
    success: false,
  },
  guides: {},
  loaded: false,
  isLoadingGuide: false,
};

export const guidesReducer = produce((draft: GuidesStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case GUIDES_LIST:
      draft = {
        ...draft,
        isLoadingGuide: true,
      };
      return draft;
    case GUIDES_PAGE_SUCCESS:
      draft = {
        ...(action.payload.data as GuidesPageStateType),
        guides: {
          ...draft.guides,
          ...(action.payload.data as GuidesPageStateType).guides,
        },
        loaded: true,
      };
      return draft;
    case GUIDES_LIST_SUCCESS:
      let guideData;
      if ((action.payload.data as GuidesResponse).meta.page === 1) {
        draft.guides[action.payload.category as string] = (action.payload.data as GuidesResponse);
        guideData = draft;
      } else {
        draft.guides[action.payload.category as string] = draft.guides[action.payload.category as string]
        ? {
            ...(action.payload.data as GuidesResponse),
            data: [
              ...draft.guides[action.payload.category as string].data,
              ...(action.payload.data as GuidesResponse).data,
            ],
          }
        : (action.payload.data as GuidesResponse);
        guideData = draft;
      }

      guideData.isLoadingGuide = false;

      return guideData;
    default:
      const newDraft = {...draft};
      newDraft.isLoadingGuide = false;
      return newDraft;
  }
});
