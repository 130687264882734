import { combineReducers } from 'redux';
import { commonReducer } from 'store/reducers/common';
import { systemConfigReducer } from 'store/reducers/system';
import { headerReducer } from 'store/reducers/header';
import { footerReducer } from 'store/reducers/footer';
import { homeReducer } from 'store/reducers/home_page';
import { casestudyReducer } from 'store/reducers/discovery/casestudy';
import { productReducer } from 'store/reducers/product';
import { whereToBuyReducer } from 'store/reducers/where_to_buy';
import { contactReducer } from 'store/reducers/contact';
import { catalogueReducer } from 'store/reducers/services/catalogue';
import { solutionReducer } from 'store/reducers/solution';
import { faqReducer } from 'store/reducers/services/faq';
import { newsReducer } from 'store/reducers/discovery/news';
import { guidesReducer } from 'store/reducers/discovery/guides';
import { smartAcSelectorReducer } from 'store/reducers/services/smart_ac_selector';
import { errorCodesReducer } from 'store/reducers/services/error_codes';
import { pichonkunReducer } from 'store/reducers/discovery/pichonkun';
import { comparisonReducer } from 'store/reducers/comparison';
import { daikinVietNamReducer } from 'store/reducers/about_us/daikin_vietnam';
import { menuServiceReducer } from 'store/reducers/services';
import { technologyReducer } from 'store/reducers/discovery/technology';
import { trainingReducer } from 'store/reducers/partner/training';
import { redirectReducer } from 'store/reducers/redirect';
import { floatingBannerReducer } from 'store/reducers/floatingBanner';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SystemConfigStateType, ActionType } from 'store/types/system';

const systemPersistConfig = {
  key: 'daikinC-system',
  storage,
  blacklist: ['loaded'],
};

export default combineReducers({
  common: commonReducer,
  system: persistReducer<SystemConfigStateType, ActionType>(systemPersistConfig, systemConfigReducer),
  header: headerReducer,
  footer: footerReducer,
  casestudy: casestudyReducer,
  home: homeReducer,
  product: productReducer,
  wheretobuy: whereToBuyReducer,
  contact: contactReducer,
  catalogue: catalogueReducer,
  solution: solutionReducer,
  faq: faqReducer,
  news: newsReducer,
  guides: guidesReducer,
  smartAcSelector: smartAcSelectorReducer,
  errorCodes: errorCodesReducer,
  pichonkun: pichonkunReducer,
  comparison: comparisonReducer,
  daikinVietNam: daikinVietNamReducer,
  menuService: menuServiceReducer,
  technology: technologyReducer,
  training: trainingReducer,
  redirect: redirectReducer,
  floatingBanner: floatingBannerReducer
});
