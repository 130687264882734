import http from 'services/http';

export interface MenuItemType {
  id: number;
  parentId: number;
  title: string;
  link: string | null;
  image?: string;
  isRight: number;
  cssClass?: string;
  target: string;
  children: Array<MenuItemType>;
  type: string;
  reference?: {
    slug: string;
    templateCode: string;
    isHomepage: number;
  };
}

export type HeaderResponse = {
  message: string;
  success: boolean;
  data: Array<MenuItemType>;
};

export type FooterResponse = {
  message: string;
  success: boolean;
  data: Array<MenuItemType>;
};

export type MenuResponse = {
  message: string;
  success: boolean;
  data: Array<{
    code: string;
    items: Array<MenuItemType>;
  }>;
};

export const getHeaderMain = async (): Promise<HeaderResponse> => {
  const { data } = await http.get('/get-menu/main_menu');
  return data;
};

export const getHeaderSecond = async (): Promise<HeaderResponse> => {
  const { data } = await http.get('/get-menu/second_menu');
  return data;
};

export const getFooterMain = async (): Promise<FooterResponse> => {
  const { data } = await http.get('/get-menu/footer_menu_1');
  return data;
};

export const getFooterSecond = async (): Promise<FooterResponse> => {
  const { data } = await http.get('/get-menu/footer_menu_2');
  return data;
};

export const getAllMenu = async (): Promise<MenuResponse> => {
  const { data } = await http.get('/get-menus?codes=main_menu,second_menu,footer_menu_1,footer_menu_2');
  return data;
};
